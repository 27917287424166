import { Tab, Tabs } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ICategory } from "../../../Models/category";
import { selectCategoryId, setCategoryId } from "../../../Stores/commonSlice";
import { CategoryOpenSelectStyles } from "./CategoryOpenSelectStyles";

interface IProps {
  categories: ICategory[];
}

const CategoryOpenSelect: React.FC<IProps> = ({ categories }) => {
  const classes = CategoryOpenSelectStyles();
  const categoryId = useSelector(selectCategoryId);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  useEffect(() => {
    if (categoryId)
      setValue(categories.findIndex((cat) => cat.id === categoryId));
  }, []);

  return (
    <Tabs
      value={value}
      onChange={(_, newValue) => setValue(newValue)}
      indicatorColor="secondary"
      textColor="secondary"
      variant="scrollable"
      scrollButtons="on"
      aria-label="scrollable auto tabs example"
      className={classes.tabContainer}
    >
      {categories.map((item) => (
        <Tab
          key={item.id}
          label={item.name}
          id={item.id}
          aria-controls={item.id}
          onClick={() => {
            dispatch(setCategoryId(item.id));
            history.push(`/store/ordering/departments/${item.id}`);
          }}
          className={classes.tabItem}
        />
      ))}
    </Tabs>
  );
};

export default CategoryOpenSelect;
