import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const TableOrderingStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableOrdering: {
      margin: 10,
      padding: 10,
    },
    description: {
      "& *": {
        margin: "5px 10px",
        textAlign: "center",
      },
    },
  })
);
