import { Redirect } from "react-router-dom";
import { ICart } from "../Models/Cart/ICart";
import CategoryChildList2 from "../components/Design2/Category2/CategoryChildList2";
import FreebiesProducts from "../components/Design2/FreebiesProducts/FreebiesProducts";
import ProductList2 from "../components/Design2/Product2/ProductList2/ProductList2";
import OrderStatus from "../components/OrderStatus/OrderStatus";

const useOrderRoutes = (cart: ICart) => {
  const routes = [
    cart?.userCart?.showFreebie
      ? {
          path: "/store/ordering/departments/freebies-products/:freebieId",
          component: FreebiesProducts,
        }
      : {
          path: "/store/ordering/departments/freebies-products",
          component: () => <Redirect to="/store/ordering/departments/" />,
        },
    {
      path: "/store/ordering/departments/:categoryId",
      component: ProductList2,
    },
    {
      path: "/store/ordering/departments",
      exact: true,
      component: CategoryChildList2,
    },
    {
      path: "/store/ordering/order-status/:orderId/:status/:paymentSenseId?/:delTime?/:note?",
      component: OrderStatus,
    },
  ];
  return routes;
};

export default useOrderRoutes;
