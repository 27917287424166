import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const ProductItemsWithImageStyles = makeStyles((theme: Theme) =>
  createStyles({
    productItems2: {
      padding: 10,
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: 20,
      transitionDuration: "0.3s",
      color: "#000",

      "@media only screen and (max-width: 600px)": {
        width: "90% !important",
        marginBottom: 10,
      },

      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        alignItems: "center",
      },

      [theme.breakpoints.up("sm")]: {
        marginBottom: 10,
      },

      "&:hover": {
        backgroundColor: theme.palette.grey[50],
      },
    },
    naming: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",

      [theme.breakpoints.down("xs")]: {
        width: "99%",
        marginTop: 5,
      },
      [theme.breakpoints.down("sm")]: {},
      [theme.breakpoints.up("sm")]: {
        flexGrow: 2,
      },

      "& .name": {
        color: theme.palette.text.primary,

        fontSize: "1rem",

        [theme.breakpoints.up("sm")]: {
          fontSize: "1.2rem",
        },

        [theme.breakpoints.up("md")]: {
          fontSize: "1.1rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      "& .desc": {
        display: "flex",
        width: "100%",
        color: theme.palette.text.primary,

        [theme.breakpoints.up("sm")]: {
          marginLeft: 10,
        },

        "& .contentStyle": {
          width: "100%",
        },
        "& .anchorStyle": {
          float: "right",
        },

        "& *": {
          margin: 0,
          padding: 0,
          lineHeight: "24px",
        },

        "& button": {
          width: 30,
          height: 30,
          [theme.breakpoints.up("sm")]: {
            marginLeft: "5px !important",
            padding: 5,
          },
          [theme.breakpoints.down("sm")]: {
            margin: 0,
            padding: "3px 10px",
            fontSize: 10,
          },
        },
      },
    },
    ordering: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      textAlign: "center",
      marginBottom: 5,
      fontSize: "1.1rem",

      paddingTop: 5,

      [theme.breakpoints.down("md")]: {
        justifyContent: "space-between",
      },
      [theme.breakpoints.up("md")]: {
        height: "100%",
        flexGrow: 0,
      },

      "& .price": {
        color: theme.palette.text.primary,

        [theme.breakpoints.up("md")]: {
          marginTop: 7,
        },
      },

      "& .whiteBg": {
        position: "absolute",
        width: 15,
        height: 15,
        backgroundColor: "#fff",
      },
      "& svg": {
        zIndex: 1,
      },
    },
    addToCartBtn: {
      marginLeft: 5,
      color: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        padding: 3,
      },
    },
    optionBtn: {
      marginLeft: 5,
      color: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        padding: 3,
      },
    },
    discounts: {
      display: "flex",
      flexWrap: "wrap",
      paddingLeft: 10,
    },
    discount: {
      display: "inline-block",

      fontSize: ".9rem",
      color: theme.palette.secondary.main,

      "&:not(:last-child)": {
        marginRight: 15,
      },
      "&:not(:last-child)::after": {
        paddingLeft: 15,
        content: "'\u2022'",
        color: theme.palette.primary.main,
      },
    },
    productImg: {
      "& img": {
        borderRadius: 20,
        objectFit: "contain",
      },

      [theme.breakpoints.down("sm")]: {
        "& img": {
          maxWidth: 180,
          maxHeight: 180,
        },
      },

      [theme.breakpoints.up("sm")]: {
        flexGrow: 0,
        "& img": {
          maxWidth: 100,
          maxHeight: 100,
        },
      },
    },
  })
);
