import React from "react";
import { Redirect, Route } from "react-router-dom";
interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
  isLoggedIn: boolean;
  isInRole: boolean;
}
const LoggedInRoute = ({
  component: Component,
  isLoggedIn,
  isInRole,
}: IProps) => (
  <Route
    render={(otherProps) => {
      if (!isLoggedIn)
        return (
          <>
            {localStorage.setItem("pushPath", window.location.pathname)}
            <Redirect to="/store/login" />
          </>
        );
      if (!isInRole) return <div>401</div>;
      return <Component {...otherProps} />;
    }}
  />
);
export default LoggedInRoute;
