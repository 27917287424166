import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ITextSlider, TextSliderType } from "../../../Models/textSlider";
import agent from "../../../Services/agent";
import { selectInformation } from "../../../Stores/informationSlice";
import { MostSaleProducts } from "../../MostSaleProducts/MostSaleProducts";
import Slider from "../../Slider/Slider";
import TemporaryShopClose from "../../TemporaryShopClose/TemporaryShopClose";
import Carousel2 from "../Carousel2/Carousel2";
import { LandingPageStyles } from "./LandingPageStyles";

const LandingPage = () => {
  const [sliders, setSliders] = useState<ITextSlider[]>([]);
  const classes = LandingPageStyles();
  const { t } = useTranslation();
  const information = useSelector(selectInformation);

  const getSliders = () => {
    agent.TextSlider.get().then((res) => setSliders(res));
  };

  useEffect(() => {
    getSliders();
  }, []);

  return (
    <>
      {information.temporaryShopClose && (
        <TemporaryShopClose
          temporaryShopCloseText={information.temporaryShopCloseText}
        />
      )}
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100%",
          zIndex: -1,
          backgroundColor: information.backGroundColor,
        }}
      >
        <Carousel2 />
      </div>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Slider
            sliders={sliders.filter((a) => a.type === TextSliderType.Simple)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={9}
          lg={6}
          container
          className={classes.root}
        >
          <Grid item xs={12} className="info">
            {information.title !== null &&
            information.title.length > 0 &&
            information.title.startsWith("<") ? (
              <>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.title }}
                ></div>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.description }}
                ></div>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.board }}
                ></div>
              </>
            ) : (
              <>
                <h2>{t("welcomeTo")}</h2>
                <h1>
                  {information.title ? information.title : information.name}
                </h1>
                <h4 className="services">{information.description}</h4>
                <p
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.board }}
                ></p>
              </>
            )}
            {!information.openOrder && information.preOrder && (
              <p className={classes.preOrder}>
                Note:&nbsp;
                <span>Online ordering is closed</span>, You can submit your
                order as pre-order and get it when online ordering is opened.
              </p>
            )}
          </Grid>
        </Grid>
        {/* <MostSaleProducts /> */}
      </Grid>
    </>
  );
};

export default LandingPage;
