import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const Carousel2Styles = makeStyles((theme: Theme) =>
  createStyles({
    carousel2: {
      position: "relative",
      "& img": {
        width: "100vw",
        height: "100vh",
        objectFit: "cover",
        filter: 'brightness(0.7)'
      },
    },
  })
);
