import {
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IAttributeCartResponse } from "../../../Models/Cart/IAttributeCartRespons";
import { ICart } from "../../../Models/Cart/ICart";
import { ICartRequest } from "../../../Models/Cart/ICartRequest";
import { ICartResponse } from "../../../Models/Cart/ICartResponse";
import { CounterMode } from "../../../Models/Enums/counterMode";
import agent from "../../../Services/agent";
import { GetStoreId } from "../../../Services/storeSession";
import {
  setCategoryId,
  setProductOptionModal,
} from "../../../Stores/commonSlice";
import { selectDelivery } from "../../../Stores/deliverySlice";
import {
  selectInformation,
  selectMoney,
} from "../../../Stores/informationSlice";
import { addToCart, selectCart } from "../../../Stores/redisCartSlice";
import Loading from "../../Loading/Loading";
import CustomModal from "../../Modal/CustomModal";
import ModifyOrderNote from "../../OptionWithRedisCart/ModifyOrderNote";
import ProductCounter from "../../ProductCounter/ProductCounter";
import { CartItemStyles } from "./CartItemStyles";

interface IProps {
  cartItem: ICartResponse;
  freebieQuantity?: number;
}

const CartItem: React.FC<IProps> = ({ cartItem, freebieQuantity }) => {
  const classes = CartItemStyles();
  const { t } = useTranslation();

  // =======================useSelector=======================
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const money = useSelector(selectMoney);
  const delivery = useSelector(selectDelivery);
  const information = useSelector(selectInformation);

  // ========================useState=========================
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  // =========================values==========================
  const storeId = GetStoreId();
  const cartId = cart?.userCart?.id ? cart.userCart.id : undefined;
  let requestCart: ICartRequest;
  if (storeId) {
    requestCart = {
      id: cartId,
      storeId: storeId,
      isFreebie: cartItem.isFreebie,
      delivery: delivery?.delivery,
      productId: cartItem.productId,
      duplicateNum: cartItem.duplicateNum,
      quantity: cartItem.quantity,
      note: cartItem.note,
      editable: cartItem.editable,
      postCode: delivery?.postCode,
      attributes: cartItem.attributes,
    };
  } else toast.warn(t("storeNotFound"));

  // =========================methods=========================
  const handleQtyChanged = (quantity: number, counterMode?: CounterMode) => {
    if (
      cartItem.isFreebie &&
      counterMode === CounterMode.Increase &&
      freebieQuantity
    ) {
      const freebiesCartItems = cart?.userCart?.carts.filter(
        (cartItem) => cartItem.isFreebie
      );

      let numberOfFreebiesCartItem = 0;
      freebiesCartItems.forEach((item) => {
        numberOfFreebiesCartItem += item.quantity;
      });

      if (numberOfFreebiesCartItem >= freebieQuantity) {
        toast.warn(
          t("youJustCanAdd") + freebieQuantity + t("freeProductSToTheCart")
        );
        return;
      }
    }
    if (storeId && requestCart) {
      let model = { ...requestCart };
      model.quantity = quantity;
      handleUpdateCart(model);
    } else toast.error(t("somethingWentWrongTryAgain"));
  };

  const handleNoteChanged = (note: string) => {
    if (storeId && requestCart) {
      let model = { ...requestCart };
      model.note = note;
      handleUpdateCart(model);
    } else toast.error(t("somethingWentWrongTryAgain"));
  };

  const handleUpdateCart = async (model: ICartRequest) => {
    if (information.openOrder || information.preOrder) {
      setLoading(true);
      await agent.Cart.post(model).then((res: ICart) => {
        setLoading(false);
        if (res) {
          dispatch(addToCart(res));
        } else {
          toast.error(t("somethingWentWrongTryAgain"));
        }
      });
    } else toast.warn(t("onlineOrderClosed"));
  };

  if (loading) return <Loading />;
  // ==========================tsx============================
  return (
    <>
      <CustomModal
        title={t("deleteProduct")}
        description={t("thisWillDeleteSelectedProduct")}
        open={modal}
        handleShow={() => setModal(false)}
        action={
          <Fragment>
            <Button
              onClick={() => setModal(false)}
              variant="contained"
              color="secondary"
            >
              {t("no")}
            </Button>
            <Button
              onClick={() => {
                handleQtyChanged(0);
                setModal(false);
              }}
              variant="contained"
              color="primary"
            >
              {t("yes")}
            </Button>
          </Fragment>
        }
      />
      <div className={classes.cartItem}>
        <div className="mainItem">
          <Typography variant="h6">{cartItem.name}</Typography>
          <span
            className={`
            price 
            currency 
            ${
              !!cartItem.discount && cartItem.discount > 0 && classes.discount
            }`}
          >
            {cartItem.editable && (
              <IconButton
                onClick={() => {
                  dispatch(setCategoryId(cartItem.categoryId));
                  dispatch(
                    setProductOptionModal({
                      open: true,
                      mainProductId: cartItem.mainProductId,
                      productId: cartItem.productId,
                      duplicateNumber: cartItem.duplicateNum,
                    })
                  );
                }}
                size="small"
              >
                <EditRoundedIcon fontSize="small" />
              </IconButton>
            )}
            {money(cartItem.total)}
          </span>
        </div>

        {!!cartItem.discount && cartItem.discount > 0 && (
          <div className="mainItem">
            <div className={classes.discountTitle}>
              <p>{cartItem.discountText}</p>
            </div>
            <span className={classes.discountPrice}>
              {money(cartItem.discount)}
            </span>
          </div>
        )}

        <ul className="extra">
          {cartItem.attributes
            .slice()
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((item: IAttributeCartResponse) => (
              <li key={item.id}>
                <Typography variant="body2" component="span">
                  {item.name}
                </Typography>
                <span className="price currency">
                  {item.price === 0
                    ? null
                    : `${money(item.price * cartItem.quantity)}`}
                </span>
              </li>
            ))}
        </ul>
        <Grid
          container
          justifyContent={cartItem.note ? "space-between" : "flex-end"}
          alignItems="center"
        >
          {cartItem.note ? (
            <ModifyOrderNote
              note={cartItem.note}
              onChange={(note) => {
                if (cartItem) handleNoteChanged(note);
              }}
            />
          ) : null}
          <div className="qty">
            <ProductCounter
              quantity={cartItem.quantity}
              handleUpdateCart={handleQtyChanged}
            />
          </div>
        </Grid>
        <Divider />
      </div>
    </>
  );
};

export default CartItem;
