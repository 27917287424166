import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { history } from "../..";
import { ITableOrder, RegisterType } from "../../Models/order";
import agent from "../../Services/agent";
import { getLocalStorage, removeLocalStorage } from "../../Services/common";
import { setCategoryId } from "../../Stores/commonSlice";
import { selectDelivery } from "../../Stores/deliverySlice";
import { selectInformation, selectMoney } from "../../Stores/informationSlice";
import { selectCart } from "../../Stores/redisCartSlice";
import { selectUser, setToken } from "../../Stores/userSlice";
import Loading from "../Loading/Loading";
import CartItem from "./CartItem/CartItem";

import GenericBtn from "../Common/GenericBtn/GenericBtn";
import { CartStyles } from "./CartStyles";

interface IProps {
  setDeliveryModal?(status: boolean): void;
}

const Cart: React.FC<IProps> = ({ setDeliveryModal }) => {
  const classes = CartStyles();
  const { t } = useTranslation();

  // =======================useSelector=======================
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const user = useSelector(selectUser);
  const information = useSelector(selectInformation);
  const money = useSelector(selectMoney);
  const delivery = useSelector(selectDelivery);

  // ========================useState=========================
  const [loading, setLoading] = useState(false);

  // =========================values==========================
  const tableOrdering: ITableOrder = getLocalStorage("tableOrdering");

  // =========================methods=========================

  const SubmitOrder = async () => {
    if (information.storeType === "Kiosk") {
      await agent.Order.tableOrder({
        cartId: cart?.userCart?.id,
        notes: "",
        numberOfPeople: 0,
        tableNumber: 0,
        orderType: RegisterType.Kiosk,
      })
        .then((res) => {
          dispatch(
            setToken({
              firstName: res.firstName,
              guest: res.guest,
              tableOrder: res.tableOrder,
              lastName: res.lastName,
              roles: ["tableOrder"],
              token: res.token,
            })
          );
          setLoading(false);
          removeLocalStorage("tableOrdering");
          history.push(`/store/payment/${res.orderId}`);
        })
        .catch((error) => {
          toast.error(t("somethingWentWrongTryAgain"));
          console.log(error);
          setLoading(false);
        });
    } else if (!!tableOrdering) {
      await agent.Order.tableOrder({
        cartId: cart?.userCart?.id,
        notes: "",
        numberOfPeople: tableOrdering.numberOfPeople,
        tableNumber: tableOrdering.tableNumber,
        orderType: RegisterType.TableOrder,
      })
        .then((res) => {
          dispatch(
            setToken({
              firstName: res.firstName,
              guest: res.guest,
              tableOrder: res.tableOrder,
              lastName: res.lastName,
              roles: ["tableOrder"],
              token: res.token,
            })
          );
          setLoading(false);
          removeLocalStorage("tableOrdering");
          history.push(`/store/payment/${res.orderId}`);
        })
        .catch((error) => {
          toast.error(t("somethingWentWrongTryAgain"));
          console.log(error);
          setLoading(false);
        });
    } else {
      await agent.Order.create({
        cartId: cart.userCart.id,
        orderType: RegisterType.Web,
      })
        .then((res) => {
          setLoading(false);
          history.push(`/store/payment/${res}`);
        })
        .catch((error) => {
          toast.error(t("somethingWentWrongTryAgain"));
          console.log(error);
          setLoading(false);
        });
    }
  };

  const handleCheckout = () => {
    if (information.storeType === "Kiosk") {
      SubmitOrder();
    } else if (
      delivery.delivery &&
      delivery.minimumOrder > 0 &&
      cart?.userCart?.subTotal - cart?.userCart?.discount <
        delivery.minimumOrder
    ) {
      toast.warn(`${t("minimumOrderIs")} ${money(delivery.minimumOrder)}`);
      return false;
    } else if (
      cart?.userCart?.carts.find((a) => a.productType === "Special") !==
        undefined &&
      cart?.userCart?.carts.find((a) => a.productType === "Food") === undefined
    ) {
      toast.warn(t("youShouldAtLeastSelectOneFoodWithThisOrder"));
      return false;
    } else if (
      (information.openOrder && !information.temporaryShopClose) ||
      information.preOrder
    ) {
      if (user.token && !user.guest && delivery.delivery)
        history.push("/store/address");
      else if (
        (user.token && !user.guest && !delivery.delivery) ||
        !!tableOrdering
      )
        SubmitOrder();
      else {
        history.push("/store/guest-ordering");
      }
    } else {
      toast.warn(t("onlineOrderingIsClosed"));
    }
  };

  if (loading) return <Loading />;
  // ==========================tsx============================
  return (
    <Grid className={classes.cart} id="cart">
      <Grid
        item
        xs={12}
        style={{
          marginBottom: 20,
        }}
      >
        <Typography className={classes.cartTitle} variant="h6">
          {t("shoppingCart")}
        </Typography>
        {delivery.delivery && delivery.minimumOrder > 0 && (
          <Typography variant="body2">
            {t("minimumDeliveryOrderIs")}&nbsp;
            {money(delivery.minimumOrder)}
          </Typography>
        )}
      </Grid>

      {cart?.userCart?.freebieShows?.length > 0 && (
        <Grid item xs={12} className={classes.discountDesc}>
          {cart.userCart.freebieShows.map((freebie) =>
            information.langDisplay === "fa-IR" ? (
              <p key={freebie.id}>
                {money(freebie.value)}&nbsp;
                {"خرید‌کنید"}&nbsp;
                {freebie.quantity}&nbsp;
                {"تا"}&nbsp;
                {t("freebies")}&nbsp;
                {t("ببرید")}&nbsp;
              </p>
            ) : (
              <p key={freebie.id}>
                {t("spend")}&nbsp;
                {money(freebie.value)}&nbsp;
                {t("get")}&nbsp;
                {freebie.quantity}&nbsp;
                {t("freebies")}
              </p>
            )
          )}

          {cart?.userCart?.showFreebie && (
            <GenericBtn
              onClick={() => {
                history.push(
                  `/store/ordering/departments/freebies-products/${cart.userCart.freebieId}`
                );
                dispatch(setCategoryId(""));
              }}
              variant="contained"
              style={{ backgroundColor: "#F7D800" }}
              fullWidth
              className="cartBtn"
              label={t("freebiesProducts")}
            />
          )}
        </Grid>
      )}

      {cart?.userCart?.carts.length > 0 && (
        <div className="cartItems">
          {cart?.userCart?.carts.map((item) => (
            <CartItem
              key={`${item.productId}${item.duplicateNum}`}
              cartItem={item}
              freebieQuantity={
                cart.userCart.freebieShows.find(
                  (a) => a.id === cart.userCart.freebieId
                )?.quantity
              }
            />
          ))}
        </div>
      )}
      <div className="totalTable">
        {cart?.userCart?.total > 0 &&
          delivery.delivery &&
          cart.userCart.deliveryCharge > 0 && (
            <div className="row">
              <span>{t("deliveryFee")}</span>
              <span>{money(cart.userCart.deliveryCharge)}</span>
            </div>
          )}
        <div className="row">
          <span>{t("subTotal")}</span>
          <span>{money(cart?.userCart?.subTotal)}</span>
        </div>
        <div className="row">
          {cart?.userCart?.discount > 0 && (
            <>
              <span className="discount">{t("discount")}</span>
              <span className="discount">
                {money(cart?.userCart?.discount)}
              </span>
            </>
          )}
        </div>
        <div className="row total">
          <span>{t("total")}</span>
          <span>{money(cart?.userCart?.total)}</span>
        </div>
      </div>

      <GenericBtn
        disabled={
          (!information.openOrder || cart?.userCart?.carts.length < 1) &&
          !information.preOrder
        }
        onClick={handleCheckout}
        variant="contained"
        color="secondary"
        fullWidth
        label={t("checkOut")}
        style={{ marginTop: 10 }}
      />
    </Grid>
  );
};
export default Cart;
