import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import CustomModal from "../Modal/CustomModal";
import { CounterMode } from "../../Models/Enums/counterMode";
import { ProductCounterStyles } from "./ProductCounterStyles";
import { DeleteOutline } from "@material-ui/icons";
import GenericBtn from "../Common/GenericBtn/GenericBtn";

interface IProps {
  quantity: number;
  handleUpdateCart(quantity: number, counterMode?: CounterMode): void;
}

const ProductCounter: React.FC<IProps> = ({ quantity, handleUpdateCart }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<boolean>(false);
  const classes = ProductCounterStyles();

  return (
    <Fragment>
      <CustomModal
        description="Remove product from cart?"
        open={modal}
        handleShow={() => setModal(false)}
        action={
          <Fragment>
            <GenericBtn
              onClick={() => setModal(false)}
              variant="contained"
              color="secondary"
            >
              {t("no")}
            </GenericBtn>
            <GenericBtn
              onClick={() => {
                handleUpdateCart(0);
                setModal(false);
              }}
              variant="contained"
              color="primary"
            >
              {t("yes")}
            </GenericBtn>
          </Fragment>
        }
      />

      <Grid container alignItems="center" className={classes.buttonGroup}>
        {quantity === 1 ? (
          <IconButton onClick={() => setModal(true)}>
            <DeleteOutline fontSize="small" />
          </IconButton>
        ) : (
          <IconButton
            aria-label="Increment value"
            onClick={() => {
              if (quantity > 1) {
                handleUpdateCart(quantity - 1);
              } else if (quantity === 1) {
                setModal(true);
              }
            }}
          >
            <RemoveIcon fontSize="small" />
          </IconButton>
        )}
        <Box className={classes.qty}>
          <Typography variant="body1">{quantity}</Typography>
        </Box>
        <IconButton
          aria-label="Decrement value"
          color="secondary"
          onClick={() => {
            handleUpdateCart(quantity + 1, CounterMode.Increase);
          }}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Fragment>
  );
};
export default ProductCounter;
