import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ICategory } from "../../../Models/category";
import agent from "../../../Services/agent";
import Loading from "../../Loading/Loading";
import CategoryChildItem2 from "./CategoryChildItem2/CategoryChildItem2";

const CategoryChildList2 = () => {
  const [menuCategories, setMenuCategories] = useState<ICategory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getMenuCategories = useCallback(async () => {
    await agent.Categories.homePageCategories().then((res) => {
      setMenuCategories(res);
      setLoading(false);
    });
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    getMenuCategories();
    window.scrollTo(0, 0);
  }, [getMenuCategories, pathname]);

  if (loading) return <Loading />;

  return (
    <Grid container>
      <Grid item xs={12} container justifyContent="space-around">
        {menuCategories.map((item) => (
          <CategoryChildItem2 key={item.id} category={item} />
        ))}
      </Grid>
    </Grid>
  );
};

export default CategoryChildList2;
