import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IProductAttributeValue } from "../../../Models/productAttributeValue";
import {
  selectInformation,
  selectMoney,
} from "../../../Stores/informationSlice";
import { selectCart } from "../../../Stores/redisCartSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      "& *": {
        borderRadius: 5,
      },
      "& label": {
        color: "#333",
        zIndex: 2,
        padding: "3px 0 0 13px",
        fontWeight: 500,
      },
      "& .MuiFormLabel-filled": {
        display: "none",
        // color: theme.palette.text.primary,
      },
      "& .MuiInput-underline": {
        borderBottom: "1px solid #9D9D9D",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover": {
        border: "1px solid #1D1D1D",
        borderBottom: "1px solid #1D1D1D",
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
      // backgroundColor: "#ccc",
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    select: {
      // height: 40,
      backgroundColor: "#E1E1E1",
      border: "1px solid #9D9D9D",
    },
  })
);

function getStyles(id: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface IProps {
  productAttributeValues: IProductAttributeValue[];
  attributeName: string;
  optionId: string;
  handleMultiDropDown(
    value: IProductAttributeValue[],
    optionId: string,
    optionName: string,
    optionDisplayOrder: number,
    attributeType: string
  ): boolean;
  attributeType: string;
  isInCart: boolean;
  productId: string;
  optionDisplayOrder: number;
  duplicateNum: number;
  preSelectedAttr?: string[];
}

const MultiSelectDropdown: React.FC<IProps> = ({
  productAttributeValues,
  attributeName,
  optionId,
  handleMultiDropDown,
  attributeType,
  isInCart,
  productId,
  optionDisplayOrder,
  duplicateNum,
  preSelectedAttr,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const money = useSelector(selectMoney);
  const [selectedOption, setSelectedOption] = useState<string[]>([]);
  const { t } = useTranslation();
  const productInCart = useSelector(selectCart);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedOption(event.target.value as string[]);
  };
  const information = useSelector(selectInformation);

  useEffect(() => {
    const product = productInCart.userCart.carts.find(
      (m) => m.productId === productId && m.duplicateNum === duplicateNum
    );
    const attribute = product
      ? product.attributes.map((item) => item.id)
      : preSelectedAttr || [];
    setSelectedOption(attribute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedAttr, productInCart]);

  return (
    <FormControl
      fullWidth
      // className={classes.formControl}
    >
      {selectedOption.length < 1 && (
        <InputLabel>
          {t("select")}&nbsp;
          {attributeName}
        </InputLabel>
      )}
      <Select
        multiple
        value={selectedOption}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          if (event.target.value) {
            const values = event.target.value as string[];
            let arr = productAttributeValues.filter(function (item) {
              return values.indexOf(item.id) !== -1;
            });
            handleChange(event);
            handleMultiDropDown(
              arr,
              optionId,
              attributeName,
              optionDisplayOrder,
              attributeType
            );
          }
        }}
        input={<Input />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {(selected as string[]).map((value) => {
              const name = productAttributeValues.find(
                (m) => m.id === value
              )?.name;
              return (
                <Chip
                  key={value}
                  label={name ? name : value}
                  className={classes.chip}
                />
              );
            })}
          </div>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: information.langDisplay === "en-GB" ? "left" : "right",
          },
          getContentAnchorEl: null,
        }}
      >
        {productAttributeValues
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .filter((m) => m.active)
          .map((option: IProductAttributeValue) => (
            <MenuItem
              key={option.id}
              value={option.id}
              style={getStyles(option.id, selectedOption, theme)}
              className="multiSelectDropDownItem"
            >
              {`${option.name} ${
                option.priceAdjustment === 0
                  ? ""
                  : money(option.priceAdjustment)
              }`}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropdown;
