/* eslint-disable eqeqeq */
import { Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { history } from "../../../..";
import { IMainWithProducts } from "../../../../Models/mainProduct";
import { ISoloProduct } from "../../../../Models/product";
import agent from "../../../../Services/agent";
import { selectProductOptionModal } from "../../../../Stores/commonSlice";
import {
  selectInformation,
  selectMoney,
} from "../../../../Stores/informationSlice";
import { selectCart } from "../../../../Stores/redisCartSlice";
import Loading from "../../../Loading/Loading";
import Options from "../../../OptionWithRedisCart/Options";
import { MainProduct2Styles } from "./MainProduct2Styles";

const MainProduct2 = () => {
  const classes = MainProduct2Styles();
  const { t } = useTranslation();

  // =======================useSelector=======================
  const information = useSelector(selectInformation);
  const money = useSelector(selectMoney);
  const { mainProductId, productId, duplicateNumber } = useSelector(
    selectProductOptionModal
  );
  const cart = useSelector(selectCart);

  // ========================useState=========================
  const [mainProduct, setMainProduct] = useState<IMainWithProducts>();
  const [product, setProduct] = useState<ISoloProduct>();

  const productInCart = cart?.userCart?.carts?.some(
    (cartItem) =>
      cartItem.productId === productId &&
      cartItem.duplicateNum === duplicateNumber
  );
  // =========================methods=========================
  const getMainProduct = (mainProductId: string, productId?: string) => {
    console.log(productId);
    agent.MainProduct.mainWithProducts(mainProductId)
      .then((res) => {
        setMainProduct(res);
        if (res.products.length > 0) {
          if (productId) {
            const product = res.products.find((m) => m.id == productId);
            if (product) {
              setProduct(product);
            }
          } else
            setProduct(
              res.products.sort((a, b) => a.displayOrder - b.displayOrder)[0]
            );
        } else {
          history.goBack();
          toast.warn("This Product Is Empty");
        }
      })
      .catch(
        (error) =>
          error.status === 400 &&
          (history.push("/404"), localStorage.setItem("lastPath", "ordering"))
      );
  };

  const handleRadioButton = (productId: string) => {
    const currentProduct = mainProduct?.products.filter(
      (m) => m.id === productId
    );

    if (currentProduct && currentProduct.length > 0) {
      setProduct(currentProduct[0]);
    }
  };

  // =======================useEffect=========================
  useEffect(() => {
    getMainProduct(mainProductId, productId);
  }, []);

  // ==========================tsx============================
  return (
    <>
      {(!mainProduct || !product) && <Loading />}
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.foodName}>
          <Typography variant="h5">
            {mainProduct?.name}
            <Typography variant="subtitle1" component="span">
              {mainProduct?.products &&
                mainProduct?.products?.length < 2 &&
                mainProduct?.products?.[0]?.price > 0 &&
                ` (${money(mainProduct?.products[0].price)})`}
            </Typography>
          </Typography>
          <hr />
        </Grid>

        <Grid item xs={12}>
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: mainProduct?.description ?? "",
            }}
          ></p>
        </Grid>

        {((mainProduct?.products && mainProduct?.products?.length > 1) ||
          information.displayProductsWithImage ||
          information.storeType === "Kiosk") && (
          <Grid item xs={12} className={classes.optionRows}>
            {(information.displayProductsWithImage ||
              information.storeType === "Kiosk" ||
              (information.storeType === "Takeaway" &&
                information.theme === "Number3")) && (
              <Grid container xs={12} justifyContent="center">
                <img
                  src={`${process.env.REACT_APP_IMG_URL}${mainProduct?.picPath}`}
                  className={classes.optionImage}
                  alt={product?.name}
                />
              </Grid>
            )}
            {mainProduct?.products && mainProduct?.products?.length > 1 && (
              <div className="attrRow crust">
                <Grid item sm={12} md={3} lg={2}>
                  <Typography variant="h6">{t("size")}</Typography>
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={9}
                  lg={10}
                  className={classes.optionsRow}
                  container
                  justifyContent="center"
                >
                  <ButtonGroup
                    color="primary"
                    size="small"
                    style={{
                      marginBottom: "12px",
                    }}
                    disabled={productInCart}
                  >
                    {mainProduct?.products
                      .sort((a, b) => a.displayOrder - b.displayOrder)
                      .map((item, i) => (
                        <Button
                          key={item.id}
                          variant={
                            item.id === product?.id ? "contained" : "outlined"
                          }
                          onClick={() => handleRadioButton(String(item.id))}
                          style={{
                            borderRadius:
                              i === 0
                                ? "12px 0 0 12px"
                                : i + 1 === mainProduct?.products.length
                                ? "0 12px 12px 0"
                                : "unset",
                          }}
                        >
                          {`${item?.name} ${
                            Number(item?.price) > 0
                              ? `(${money(item?.price)})`
                              : ""
                          }`}
                        </Button>
                      ))}
                  </ButtonGroup>
                </Grid>
              </div>
            )}
          </Grid>
        )}
        {product && <Options product={product} />}
      </Grid>
    </>
  );
};

export default MainProduct2;
