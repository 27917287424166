import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const CategoryOpenSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {
      position: "sticky",
      top: 0,
      backgroundColor: "#fff",
      paddingBottom: 5,
      borderBottom: "1px solid #eee",
      zIndex: 2,
    },
    tabItem: {
      color: theme.palette.text.primary,
    },
  })
);
