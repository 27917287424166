import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const LoadingStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",

      "& .MuiCircularProgress-indeterminate": {
        animation: "circular-rotate 1.4s linear infinite",
      },

      "& @keyframes circular-rotate": {
        "& 0%": {
          transform: "rotate(0deg)",
          /* Fix IE11 wobbly */
          transformOrigin: "50% 50%",
        },
        "& 100%": {
          transform: "rotate(360deg)",
        },
      },
    },
  })
);
