import { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Main from "./Layouts/Main";
import { IDeliveryCollection } from "./Models/deliveryCharge";
import Subscriber from "./Pages/Subscriber";
import { getLocalStorage } from "./Services/common";
import { setDelivery } from "./Stores/deliverySlice";
import { selectUser } from "./Stores/userSlice";
import AddToHomeScreen from "./components/Common/AddToHomeScreen/AddToHomeScreen";
import LoggedInRoute from "./components/Common/LoggedInRoute/LoggedInRoute";
import Loading from "./components/Loading/Loading";

const Admin = lazy(() => import("./Layouts/Admin/Admin"));
const OrderEntryLayout = lazy(() => import("./Layouts/OrderEntryLayout"));

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isAdmin = user && user.roles.indexOf("Administrator") !== -1;

  const readPostCode = () => {
    let postCode: IDeliveryCollection = getLocalStorage("postCode");
    if (postCode) {
      dispatch(
        setDelivery({
          delivery: postCode.delivery,
          postCode: postCode.postCode,
          deliveryCharge: postCode.deliveryCharge,
          minimumOrder: postCode.minimumOrder,
        })
      );
    } else {
      dispatch(
        setDelivery({
          delivery: false,
          postCode: "",
          deliveryCharge: 0,
          minimumOrder: 0,
        })
      );
    }
  };

  useEffect(() => {
    readPostCode();
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Suspense fallback={<Loading />}>
        <Switch>
          <LoggedInRoute
            isLoggedIn={!!user.token}
            isInRole={isAdmin}
            path="/panel"
            component={Admin}
          />
          <LoggedInRoute
            isLoggedIn={!!user.token}
            isInRole={isAdmin}
            path="/store/order"
            component={OrderEntryLayout}
          />
          <Route path="/store" component={() => <Main />} />
          <Route path="/" component={() => <Subscriber />} />
        </Switch>
        <AddToHomeScreen />
      </Suspense>
    </div>
  );
};

export default App;
