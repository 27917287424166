import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const SliderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100vw",
      padding: 10,
      backgroundColor: `${theme.palette.primary.main}b8`,
      "& p": {
        color: "#fff",
        fontSize: "1.6rem",
        textAlign: "center",
         

        [theme.breakpoints.down("xs")]: {
          fontSize: "1rem",
        },
      },
      "& a": {
        marginLeft: 10,
        padding: 5,
        borderRadius: 5,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  })
);
