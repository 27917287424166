import { Button, ButtonProps } from "@material-ui/core";
import React from "react";

interface IProps extends ButtonProps {
  label?: string;
  icon?: React.ReactNode;
}

const GenericBtn: React.FC<IProps> = ({ label, icon, ...props }) => {
  return (
    <Button
      {...props}
      startIcon={icon}
      style={{
        margin: 5,
        borderRadius: 20,
        ...props.style,
      }}
    >
      {label}
      {props.children}
    </Button>
  );
};

export default GenericBtn;
