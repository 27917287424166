import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { AxiosResponse } from "axios";
import queryString from "query-string";

import { Button, Grid, Paper } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";

import { history } from "../..";
import { IOrderDisplay } from "../../Models/order";
import agent from "../../Services/agent";
import { selectInformation } from "../../Stores/informationSlice";
import { emptyCart } from "../../Stores/redisCartSlice";
import { isGuest } from "../../Stores/userSlice";
import Loading from "../Loading/Loading";

import { OrderStatusStyles } from "./OrderStatusStyles";

interface IProps {
  match: any;
}

const OrderStatus: React.FC<IProps> = ({ match }) => {
  const classes = OrderStatusStyles();
  const { t } = useTranslation();
  const orderId = match.params.orderId;
  const dispatch = useDispatch();
  const status = match.params.status as string;
  const paymentSenseId = match.params.paymentSenseId as string | undefined;
  const delTime =
    match.params.delTime === null ? null : new Date(+match.params.delTime);
  const note = match.params.note as string | undefined;
  const guest = useSelector(isGuest);
  const parsed = queryString.parse(window.location.search);
  let connectStatusCode: string | undefined =
    parsed.connectStatusCode?.toString();
  const connectAuthCode: string | undefined =
    parsed.connectAuthCode?.toString();
  const [order, setOrder] = useState<IOrderDisplay>();
  const information = useSelector(selectInformation);

  const getOrder = useCallback(async () => {
    if (status === "cancel") {
      dispatch(emptyCart());
      return;
    } else if (
      status === "paymentSense" &&
      paymentSenseId &&
      (connectStatusCode === "0" || connectAuthCode)
    ) {
      agent.Order.edit(
        {
          id: orderId,
          payType: "PaymentSense",
          payPalOrderId: paymentSenseId,
          note: note ?? "",
          deliveryTime: delTime,
        },
        orderId
      )
        .then(() => {
          dispatch(emptyCart());
          window.location.replace(
            `/store/ordering/order-status/${orderId}/success`
          );
        })
        .catch((error: AxiosResponse) => {
          console.log(error);

          if (error.status === 409)
            toast.error(t("yourPurchaseHadProblemCallToManager"));
        });
    } else if (status === "success" && paymentSenseId === "nochex") {
      agent.Order.edit(
        {
          id: orderId,
          payType: "Nochex",
          note: note ?? "",
          deliveryTime: delTime,
        },
        orderId
      )
        .then(() => {
          dispatch(emptyCart());
          window.location.replace(
            `/store/ordering/order-status/${orderId}/success`
          );
        })
        .catch((error: AxiosResponse) => {
          if (error.status === 409)
            toast.error(t("yourPurchaseHadProblemCallToManager"));
        });
    } else
      await agent.Order.solo(orderId).then((res) => {
        setOrder(res);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const message = (connectStatusCode: string): string => {
    switch (connectStatusCode) {
      case "4":
        // return "The card issuer has parked the transaction awaiting contact with the customer before proceeding to authorise or decline the transaction.";
        return t("thePaymentIsDeclined");
      case "5":
        return t("paymentWasNotAuthorisedDueToAddressVerificationFail");
      case "20":
        return t("theTransactionWhichWasProcessedWasDuplicate");
      case "30":
        return t("errorExecutingTransaction");
      case "400":
        return t("theRequestAsFailedValidationByOurServers");
      case "401":
        return t("theAccessTokenBeingUsedIsNotValid");
      case "404":
        return t("noAccessTokenHasBeenSuppliedToConnect");
      case "500":
        return t("theresBeenAnErrorSubmittingTheTransaction");
      default:
        return "";
    }
  };

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  if (!order && status !== "cancel") return <Loading />;

  return (
    <Grid container component={Paper} className={classes.orderStatus}>
      <Grid item xs={12}>
        {status === "success" && order?.orderStatusId !== "Register" ? (
          <Zoom in={true} timeout={1000}>
            <div className="success">
              <h3>{t("yourOrderConfirmedSuccessfully")}</h3>
              <h1>{order?.customOrderNumber}</h1>
              <h6>{t("orderTrackingNumber")}</h6>
            </div>
          </Zoom>
        ) : (
          <Zoom in={true} timeout={1000}>
            <div className="wrong">
              <p className="desc">
                {t("somethingWentWrongIfYouPayForOrderCallToManager")}
              </p>
              <p className="errorMessage">
                ({t("errorMessage")}
                {message(connectStatusCode ?? "")})
              </p>
            </div>
          </Zoom>
        )}
      </Grid>

      {!guest &&
        !(
          information.storeType === "Kiosk" ||
          information.storeType === "OriginKiosk"
        ) && (
          <Grid item container justifyContent="center" xs={12}>
            <Button
              onClick={() => history.push("/store/profile/orders")}
              variant="contained"
              color="primary"
              size="large"
            >
              {t("checkYourOrders")}
            </Button>
          </Grid>
        )}
    </Grid>
  );
};

export default OrderStatus;
