import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const DeliveryCollectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGroup: {
      display: "flex",
      justifyContent: "center",
    },
    btnGroup: {
      marginBottom: 15,
      display: "flex",
      justifyContent: "center",

      "& .whiteColorBtn": {
        color: "#fff",
        borderColor: "#fff",
        marginRight: "unset !important",
        marginLeft: "unset !important"
      }
    },
    postCode: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    enterPostCode: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
    },
    postCodeResult: {
      "& h5": {
        margin: "10px 0 0",
         
        textAlign: "center",
      },
    },
    postCodeResultBtn: {
      display: "flex",
      flexDirection: "column",
    },
    collection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",

      "& p": {
        padding: "16px 0",
        margin: 0,
        textAlign: "center",
         
      },
    },
  })
);
