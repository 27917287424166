import { Theme, createStyles, makeStyles } from "@material-ui/core";
export const CHoverMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: "block",
      color: theme.customPalette.menuText,

      "&:first-child": {
        paddingTop: 5,
        borderRadius: "5px 5px 0 0",
      },
      "&:last-child": {
        paddingBottom: 5,
        borderRadius: "0 0 5px 5px",
      },
    },
    hoverMenu: {
      padding: "0 5px",
      "&:last-child": {
        borderRight: "none",
      },
      "& a": {
        display: "flex",
        alignItems: "center",
        padding: "0 10px",

        color: `${theme.customPalette.menuText} !important`,
        cursor: "pointer",

        "&:hover": {
          color: theme.customPalette.menuText,
          filter: "brightness(0.5)",
          textDecoration: "none",
          opacity: 0.9,
        },
      },
    },
  })
);
