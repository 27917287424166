import { Grid, Link, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getHtmlTextContent } from "../../../Services/common";
import { selectInformation } from "../../../Stores/informationSlice";
import { MainSkinTheme } from "../../../Themes/MainSkinTheme";
import Logo from "../../Logo/Logo";
import { FooterStyles } from "./Footer.styles";
import WavyFooter from "./WavyFooter";

type TProps = {
  facebook: string;
  twitter: string;
  instagram: string;
  whatsapp: string;
  hasOnlinePayment: boolean;
  hasPayPal: boolean;
  isSubscriber?: true | boolean;
};

const Footer: React.FC<TProps> = ({
  facebook,
  twitter,
  instagram,
  whatsapp,
  isSubscriber,
}) => {
  const theme = MainSkinTheme();
  const { t } = useTranslation();
  const classes = FooterStyles();
  const information = useSelector(selectInformation);

  return (
    <>
      <WavyFooter
        firstWaveColor={theme.palette.primary.main ?? ""}
        secondWaveColor={theme.palette.footerBg.main ?? ""}
      />
      <Grid
        container
        justifyContent="space-around"
        className={classes.footer}
        spacing={1}
      >
        <Grid item xs={12} sm={6} container>
          {/* LOGO */}
          <Grid
            item
            xs={12}
            md={3}
            container
            justifyContent="flex-start"
            className={classes.logo}
          >
            {isSubscriber ? null : <Logo />}
          </Grid>
          {/* Store desc */}
          <Grid item xs={12} md={3} className={classes.logo}>
            {information.description ? (
              <Typography variant="body2">
                {getHtmlTextContent(information.description)}
              </Typography>
            ) : null}
          </Grid>
          {/* Social Icon Links */}
          {facebook || instagram || twitter || whatsapp ? (
            <Grid item xs={12} md={3} className={classes.socialLinks}>
              {facebook && (
                <Link href={facebook} target="_blank" rel="noopener noreferrer">
                  <FacebookIcon />
                </Link>
              )}
              {instagram && (
                <Link
                  href={instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon />
                </Link>
              )}
              {twitter && (
                <Link href={twitter} target="_blank" rel="noopener noreferrer">
                  <TwitterIcon />
                </Link>
              )}
              {whatsapp && (
                <Link href={whatsapp} target="_blank" rel="noopener noreferrer">
                  <WhatsAppIcon />
                </Link>
              )}
            </Grid>
          ) : null}
        </Grid>
        {/* Contacts */}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          container
          direction="column"
          className={classes.contactUs}
        >
          {(information.tell ||
            information.detailTell ||
            information.phoneNumber) && (
            <Typography variant="h6" color="primary">
              Contact us
            </Typography>
          )}
          {(information.tell || information.detailTell) && (
            <>
              <Typography variant="button" color="inherit">
                Our location:
              </Typography>
              <Typography variant="caption" color="primary">
                {getHtmlTextContent(information.tell ?? information.detailTell)}
              </Typography>
            </>
          )}
          {information.phoneNumber && (
            <>
              <Typography variant="button" color="inherit">
                Phones:
              </Typography>
              <Typography variant="caption" color="primary">
                {getHtmlTextContent(information.phoneNumber)}
              </Typography>
            </>
          )}
        </Grid>
        {/* Copy right */}
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          className={classes.copyRight}
        >
          <Typography variant="caption" gutterBottom>
            {t("copyright")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
