import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Grid,
  Paper,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { FORM_ERROR } from "final-form";
import queryString from "query-string";
import { combineValidators, composeValidators, isRequired } from "revalidate";

import { history } from "../../..";
import { IResetPassword } from "../../../Models/user";
import { isValidEmail } from "../../../Services/CustomValidate";
import agent from "../../../Services/agent";
import { setToken } from "../../../Stores/userSlice";
import ErrorMessage from "../../../utils/ErrorMessage";
import TextInput from "../../../utils/TextInput";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const parsed = queryString.parse(window.location.search);
  var token: string | undefined = parsed.token?.toString();

  const validate = combineValidators({
    userName: composeValidators(
      isValidEmail(),
      isRequired({ message: t("required") })
    )(),
    password: isRequired({ message: t("required") }),
    confirmPassword: isRequired({ message: t("required") }),
  });
  const resetPass: IResetPassword = {
    userName: "",
    token: token ?? "",
    password: "",
    confirmPassword: "",
  };
  const dispatch = useDispatch();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6} style={{ margin: "1rem" }}>
        <Paper style={{ padding: "1rem" }}>
          <Typography component="h1" variant="h5">
            {t("changePassword")}
          </Typography>
          <Form
            validate={validate}
            initialValues={resetPass}
            onSubmit={(values: IResetPassword) =>
              agent.User.resetPassword(values)
                .then((res) => {
                  toast.success(t("yourPasswordChangedSuccessfully"));
                  dispatch(setToken(res));
                  history.push("/store");
                })
                .catch((error) => ({
                  [FORM_ERROR]: error,
                }))
            }
            render={({
              handleSubmit,
              invalid,
              pristine,
              submitError,
              dirtySinceLastSubmit,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                {/* {submitError && submitError.data.errors && !dirtySinceLastSubmit && ( */}
                <Field
                  autoFocus
                  label={t("emailAddress")}
                  name="userName"
                  component={TextInput}
                  value={resetPass.userName}
                  placeholder={t("emailAddress")}
                />
                <Field
                  component={TextInput}
                  label={t("password")}
                  name="password"
                  type="password"
                  value={resetPass.password}
                  placeholder={t("password")}
                />
                <Field
                  component={TextInput}
                  label={t("confirmPassword")}
                  name="confirmPassword"
                  type="password"
                  value={resetPass.confirmPassword}
                  placeholder={t("confirmPassword")}
                />
                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage text="Error(s)" error={submitError} />
                )}
                <Grid container justifyContent="flex-end">
                  <Button
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                    type="submit"
                  >
                    {t("submit")}
                  </Button>
                </Grid>
                {/* <pre dir="ltr">{JSON.stringify(form.getState(), null, 2)}</pre> */}
              </form>
            )}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
