import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const Options2Styles = makeStyles((theme: Theme) =>
  createStyles({
    OptionsStyles: {
      "& .discount": {
        color: theme.palette.secondary.main,
      },

      "& .optionRows": {
        "@media (max-width: 767px)": {
          padding: "0 1rem",
        },
      },

      "& .description": {
        fontSize: "1.3rem",
        paddingLeft: "1rem",
        "& table": {
          display: "block",
          width: "100%",
          "& tbody": {
            display: "block",
          },
        },
      },

      "& .options": {
        padding: "2rem",
        "& h6": {
          display: "block",
          color: "#011f2d",
          fontSize: "1rem",

          // marginRight: "2rem",
          whiteSpace: "nowrap",
          minWidth: "fit-content",
          "@media only screen and (min-width: 1200px)": {
            fontSize: "1rem",
          },
        },
      },

      "& .note": {
        "& textarea": {
          display: "block",
          height: 200,
          width: 500,
          border: "solid 1px #707070",
          borderRadius: 5,
          padding: "1rem",
          margin: "0 auto",
          overflowY: "hidden",
          "@media (max-width: 767px)": {
            width: 300,
          },
        },
      },

      "& .pricing": {
        padding: 10,
        backgroundColor: "#f7f0e2",
        borderRadius: 5,
        "& .itemPrice": {},
      },

      "& .products": {
        "& .close": {
          color: "#441d0b",
        },
      },

      "& .product": {
        borderBottom: "1px solid #ebedf3",
        marginBottom: "1rem",

        "& ul": {
          listStyle: "none",
        },

        "& li": {
          textAlign: "left",
        },

        "& li::before": {
          content: "\u2022",
          color: "#441d0b",
          fontWeight: "bold",
          display: "inline-block",
          width: "1rem",
          marginLeft: "-1em",
          fontSize: "1.5rem",
          lineHeight: 0,
          verticalAlign: "sub",
        },

        "& span": {
          display: "inline-block",
          marginLeft: "1rem",
        },

        "& a": {
          color: "#51861e",
        },
      },

      "& .productName": {
        paddingBottom: 0,
        color: "#0c2936",
        textAlign: "left",
        borderBottom: "none",

        "& span": {
          float: "right",
        },
      },

      "& .waysToPay": {
        margin: "2rem 0",
        "& input": {
          margin: "0 0.5rem",
        },

        "& label": {
          margin: "0 0.5rem",
        },

        "& img": {
          width: 30,
          height: 20,
          margin: "0 1rem",
        },
      },

      "& .confirmBtn": {
        width: 200,
        padding: "1rem",
        color: "#fff",
        fontSize: "1rem",
        fontWeight: "bold",
        backgroundColor: "#441d0b",
        border: "solid 2px rgba(255, 255, 255, 0)",
        borderRadius: "2rem",

        "&:hover": {
          backgroundColor: "#441d0b",
          border: "solid 2px #2b3c47",
        },
      },

      "& .total": {
        "& p": {
          textAlign: "left",
        },

        "& p:last-child": {
          fontWeight: "bold",
          backgroundColor: "#ebebeb",
          borderRadius: 5,
          padding: "1rem",
        },

        "& span": {
          float: "right",
        },
      },
    },

    orderBtn: {
      "& .orderNoteBtn": {},
    },

    actions: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",

      "& .addToCart": {
        whiteSpace: "nowrap",
        marginLeft: "0.5rem",
      },
    },

    OptionsTable: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: "0",
      marginBottom: "1rem",

      "& .optionTobody": {
        "@media(max-width:959px)": {
          display: "flex",
          flexDirection: "column",
        },
      },

      "& th": {
        verticalAlign: "top",
      },

      "& td": {
        verticalAlign: "top",
      },

      "& .discountTr": {
        marginBottom: "1rem",

        "@media(max-width:959px)": {
          display: "flex",
          flexDirection: "column",
          marginBottom: "0.5rem",
        },

        "& th": {
          "@media(min-width:960px)": {
            paddingBottom: "0.5rem",
          },
        },

        "& h6": {
          marginBottom: "0",
        },
      },

      "& .discountHrTr": {
        "@media(max-width:959px)": {
          display: "flex",
          flexDirection: "column",
        },
      },
    },

    attrRow: {
      "@media(max-width:959px)": {
        display: "flex",
        flexDirection: "column",
      },
      "& .MuiFormLabel-root": {
        zIndex: 2,
        color: "#333333",
      },
      "& .MuiFormLabel-filled": {
        color: theme.palette.primary.main,
      },
      "& .MuiSelect-select": {
        color: "#333333",
        backgroundColor: "#E1E1E1",
      },
      "& .MuiSelect-icon": {
        color: "#333333",
      },
      "& .MuiList-root": {
        color: "#333333",
        backgroundColor: "#E1E1E1",
      },
    },

    attrRowHr: {
      "@media(max-width:959px)": {
        display: "flex",
        flexDirection: "column",
      },
    },

    optionsRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },

    quantity: {
      "& .value": {
        padding: "0 0.7rem",
        margin: "0 0.5rem",
      },
      "& .productCounter": {
        borderRadius: 4,
        border: "1px solid black",
        padding: "0 1rem",
        "@media only screen and (max-width: 600px)": {
          width: 170,
        },
      },
    },

    CheckboxContiner: {
      fontSize: "1.1rem",
    },

    checkBoxTags: {
      listStyle: "none",
      display: "inline",

      "& label": {
        display: "inline-block",
        margin: "0 5px 7px 0",
        padding: "3px 10px",
        borderRadius: 15,
        WebkitTapHighlightColor: "transparent",
        transition: "all .2s",
        cursor: "pointer",
        userSelect: "none",
        border: "1px solid" + theme.palette.grey[300],

        "&::before": {
          display: "inline-block",
          width: 17,
          WebkitFontSmoothing: "antialiased",

          fontSize: 16,
          padding: "2px 6px 2px 2px",
          transition: "transform .3s ease-in-out",
        },

        "&:active": {
          transform: "translateY(1px)",
        },
      },

      '& input[type="checkbox"]': {
        position: "absolute",
        opacity: 0,
        "&:checked + label": {
          backgroundColor: theme.palette.primary.light,
          opacity: 0.7,
          color: "#fff",
          transition: "all .2s",
        },
      },
    },

    extra2Row: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",

      "& label": {
        margin: "0 0.5rem 0.5rem 0",
      },

      "& span": {
        fontSize: "1.2rem",
        padding: "0 0.3rem 0 0 !important",
      },
    },

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },

    chips: {
      display: "flex",
      flexWrap: "wrap",
    },

    chip: {
      margin: 2,
    },

    noLabel: {
      marginTop: theme.spacing(3),
    },

    toppingDesc: {
      fontWeight: 400,
      fontSize: "0.9rem",
      color: theme.palette.secondary.main,
    },

    optionsListGrid: {
      display: "flex",
      marginBottom: "1rem",

      "@media(max-width:959px)": {
        flexDirection: "column",

        "& h6": {
          marginBottom: "0.5rem",
        },
      },

      "& .titleGridOfMultiSelect": {
        alignSelf: "center",

        "@media(max-width:959px)": {
          alignSelf: "unset",
        },
      },
    },

    discountGrid: {
      display: "flex",

      "@media(max-width:959px)": {
        flexDirection: "column",
        marginBottom: "0.5rem",
      },

      "& h6": {
        marginBottom: "0",
        "@media(min-width:960px)": {
          paddingBottom: "0.5rem",
        },
      },
    },
  })
);
