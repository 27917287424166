import { useSelector } from "react-redux";
import { selectInformation } from "../Stores/informationSlice";
import { createTheme } from "@material-ui/core";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    bg: Palette["primary"];
    header: Palette["primary"];
    title: Palette["primary"];
    footerBg: Palette["primary"];
  }
  interface PaletteOptions {
    bg?: PaletteOptions["primary"];
    header?: PaletteOptions["primary"];
    title?: PaletteOptions["primary"];
    footerBg?: PaletteOptions["primary"];
  }
}

declare module "@material-ui/core/styles" {
  interface Theme {
    customPalette: {
      menuText: React.CSSProperties["color"];
      menuBg: React.CSSProperties["color"];
      fontHeaderColor: React.CSSProperties["color"];
    };
  }

  interface ThemeOptions {
    customPalette: {
      menuText: React.CSSProperties["color"];
      menuBg: React.CSSProperties["color"];
      fontHeaderColor: React.CSSProperties["color"];
    };
  }
}

export const MainSkinTheme = () => {
  const information = useSelector(selectInformation);

  return createTheme({
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: information.darkTheme ? "#00000050" : "#ffffffb5",
        },
      },
    },
    direction: information.lang === "Persian" ? "rtl" : "ltr",
    typography: {
      fontFamily:
        information.lang === "Persian" ? "Samim" : "Roboto, sans-serif",
    },
    palette: {
      type: information.darkTheme ? "dark" : "light",
      // Main
      primary: {
        main: information.mainColor ?? "#1976d2",
      },
      // Accent
      secondary: {
        main: information.accentColor ?? "#9c27b0",
      },
      // Background
      bg: {
        main: information.backGroundColor
          ? information.backGroundColor
          : "#eee",
      },
      footerBg: {
        main: information.headerColor ?? "#333e48",
      },
      // Header
      header: {
        main: information.headerColor || "#aaa",
      },
      // Text
      title: {
        main: information.textColor || "#fff",
      },
      background: {
        paper: information.darkTheme ? "#424242" : "#ffffff",
        default: information.backGroundColor ?? "#eee",
      },
      text: {
        primary: information.textMenuColor ?? "#000",
        secondary: information.fontHeaderColor ?? "#fff",
      },
    },
    customPalette: {
      menuText: information.textMenuColor ?? "#000",
      menuBg: information.backGroundMenuColor ?? "#fff",
      fontHeaderColor: information.fontHeaderColor ?? "#fff",
    },
  });
};
