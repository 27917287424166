import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const OrderingStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      backgroundColor: theme.palette.bg.main,
      height: "100%",
    },
    rootContainer: {
      padding: "1rem 0",

      "@media(max-width: 959px)": {
        padding: "0.5rem 0",
      },
    },
    rootContainerOfKiosk: {
      padding: "1rem 1rem 0.1rem 1rem",
      "@media(max-width: 959px)": {
        padding: "0.5rem",
      },
    },
    products: {
      padding: "0 1rem",
      "@media(max-width: 959px)": {
        padding: "0 0.5rem",
      },
      "@media(max-width: 599px)": {
        padding: 0,
        marginBottom: "0.5rem",
      },
    },
    sticky: {
      position: "sticky",
      top: "1rem",
    },
    scrollMenu: {
      position: "sticky",
      padding: "1rem 1rem 2rem 0px",
      left: 0,
      overflowY: "scroll",
      top: 0,
      direction: "rtl",

      /* scrollbar width */
      "&::-webkit-scrollbar": {
        width: 20,
      },

      /* scrollbar Track */
      "&::-webkit-scrollbar-track": {
        background: theme.palette.bg.main,
      },

      /* scrollbar Handle */
      "&::-webkit-scrollbar-thumb": {
        border: `8px solid ${theme.palette.bg.main}`,
        borderRadius: 20,
        background: "#fff",
      },

      /* scrollbar Handle on hover */
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#eee",
      },
    },
    scrollMain: {
      // firefox
      scrollbarWidth: "thin",
      scrollbarColor: `${theme.palette.primary.main} #eee`,
      margin: "0 1rem",

      "@media(max-width: 959px)": {
        margin: "0 0.5rem",
      },

      "@media(max-width: 599px)": {
        margin: 0,
      },

      [theme.breakpoints.down("xs")]: {
        height: "auto !important",
      },

      [theme.breakpoints.up("sm")]: {
        position: "sticky",
        overflowY: "scroll",
        left: 0,
        top: 0,

        /* scrollbar width */
        "&::-webkit-scrollbar": {
          width: 4,
        },

        /* scrollbar Track */
        "&::-webkit-scrollbar-track": {
          background: "none",
        },

        /* scrollbar Handle */
        "&::-webkit-scrollbar-thumb": {
          borderRadius: 20,
          background: theme.palette.primary.main,
        },

        /* scrollbar Handle on hover */
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#eee",
        },

        "&::-webkit-scrollbar-corner": {
          paddingTop: 10,
        },
      },
    },
    scrollMainOfKiosk: {
      [theme.breakpoints.down("xs")]: {
        height: "auto !important",
      },

      [theme.breakpoints.up("sm")]: {
        position: "sticky",
        overflow: "hidden",
        overflowY: "scroll",
        left: 0,
        top: 0,

        /* scrollbar width */
        "&::-webkit-scrollbar": {
          width: 4,
        },

        /* scrollbar Track */
        "&::-webkit-scrollbar-track": {
          background: "none",
        },

        /* scrollbar Handle */
        "&::-webkit-scrollbar-thumb": {
          borderRadius: 20,
          background: "#fff",
        },

        /* scrollbar Handle on hover */
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#eee",
        },

        "&::-webkit-scrollbar-corner": {
          paddingTop: 10,
        },
      },
    },
    scrollProducts: {
      margin: "0 1rem",

      "@media(max-width: 959px)": {
        margin: "0 0.5rem",
      },

      "@media(max-width: 599px)": {
        margin: 0,
        marginBottom: "0.5rem",
      },
    },
  })
);
