import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const CartStyles = makeStyles((theme: Theme) =>
  createStyles({
    cart: {
      padding: "1rem",

      "& .deliveryCollection": {
        margin: 0,

        [theme.breakpoints.down("xs")]: {
          margin: 0,
        },
        [theme.breakpoints.up("md")]: {
          margin: 5,
        },
        "& .desc": {
          fontSize: 12,
          lineHeight: "initial",
        },
      },
      "& .totalTable": {
        marginTop: 20,

        "& .row": {
          display: "flex",
          justifyContent: "space-between",
          padding: "5px 7px",
          margin: "0 !important",
        },

        "& .total": {
          padding: "0 7px",
          borderRadius: 5,
          color: theme.palette.primary.main,
        },
        "& .discount": {
          color: theme.palette.secondary.main,
        },
      },
    },
    cartTitle: {
      fontSize: "1.5rem",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    discountDesc: {
      "& p": {
        color: theme.palette.info,
        padding: "5px 0px",
        textAlign: "center",
        borderRadius: 5,
      },
    },
  })
);
