import { Grid, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  combineValidators,
  composeValidators,
  isNumeric,
  isRequired,
} from "revalidate";
import { ITableOrdering } from "../../../Models/tableOrdering";
import { isGreaterThan } from "../../../Services/CustomValidate";
import { getLocalStorage, setLocalStorage } from "../../../Services/common";
import NumberInput from "../../../utils/NumberInput";
import TextInput from "../../../utils/TextInput";
import BackgroundImage from "../../Common/BackgroundImage/BackgroundImage";
import GenericBtn from "../../Common/GenericBtn/GenericBtn";
import { TableOrderingStyles } from "./TableOrderingStyles";

const TableOrdering = () => {
  const classes = TableOrderingStyles();
  const { t } = useTranslation();
  const params: any = useParams();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<ITableOrdering>({
    tableNumber: params.tableNumber ?? NaN,
    fullName: "",
    numberOfPeople: NaN,
  });

  const validate = combineValidators({
    tableNumber: composeValidators(
      isNumeric({ message: t("mustBeNumber") }),
      isGreaterThan(0)({ field: t("tableNo") }),
      isRequired({ message: t("required") })
    )(),
    numberOfPeople: composeValidators(
      isNumeric({ message: t("mustBeNumber") }),
      isGreaterThan(0)({ field: t("numberOfPerson") }),
      isRequired({ message: t("required") })
    )(),
  });

  const handleSubmit = (values: ITableOrdering) => {
    setLocalStorage("tableOrdering", {
      tableNumber: values.tableNumber,
      fullName: values.fullName,
      numberOfPeople: values.numberOfPeople,
    });
    history.push("/store/ordering/departments");
  };

  useEffect(() => {
    const tableOrdering: ITableOrdering = getLocalStorage("tableOrdering");
    if (!!tableOrdering) {
      setInitialValues({
        fullName: tableOrdering.fullName,
        numberOfPeople: tableOrdering.numberOfPeople,
        tableNumber: tableOrdering.tableNumber,
      });
    }
  }, []);

  return (
    <Grid container justifyContent="center">
      <BackgroundImage />
      <Grid
        item
        xs={12}
        sm={10}
        lg={6}
        component={Paper}
        className={classes.tableOrdering}
        container
        justifyContent="center"
      >
        <Grid item xs={12} className={classes.description}>
          <h4>{t("tableOrdering")}</h4>
          <hr />
          <p>{t("pleaseEnterYourDetails")}</p>
        </Grid>
        <Form
          validate={validate}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Grid container style={{ padding: "1rem" }} spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  container
                  justifyContent="center"
                >
                  <Field
                    label={t("tableNo")}
                    name="tableNumber"
                    component={NumberInput}
                    min={1}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  container
                  justifyContent="center"
                >
                  <Field
                    label={t("fullName")}
                    name="fullName"
                    component={TextInput}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  container
                  justifyContent="center"
                >
                  <Field
                    label={t("numberOfPerson")}
                    name="numberOfPeople"
                    component={NumberInput}
                    min={1}
                  />
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <GenericBtn
                    label={t("submitNext")}
                    type="submit"
                    disabled={invalid}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default TableOrdering;
