import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";
import { ICategory } from "../../../../Models/category";
import { setCategoryId } from "../../../../Stores/commonSlice";
import { CategoryChildItem2Styles } from "./CategoryChildItem2Styles";

interface IProps {
  category: ICategory;
}

const CategoryChildItem2: React.FC<IProps> = ({ category }) => {
  const classes = CategoryChildItem2Styles();
  const dispatch = useDispatch();

  return (
    <Link
      to={`/store/ordering/departments/${category.id}`}
      className={classes.root}
      onClick={() => {
        dispatch(setCategoryId(category.id));
      }}
    >
      <img src={`${process.env.REACT_APP_IMG_URL}${category.picPath}`} alt="" />
      <Typography variant="h3">{category.name}</Typography>
    </Link>
  );
};

export default CategoryChildItem2;
