import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ShowMoreText from "react-show-more-text";
import { toast } from "react-toastify";

import { Grid, IconButton } from "@material-ui/core";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import { ICart } from "../../../../../Models/Cart/ICart";
import { ICartRequest } from "../../../../../Models/Cart/ICartRequest";
import { IMainProduct } from "../../../../../Models/mainProduct";
import { ITierPrice } from "../../../../../Models/tierPrices";
import agent from "../../../../../Services/agent";
import { GetStoreId } from "../../../../../Services/storeSession";
import { selectDelivery } from "../../../../../Stores/deliverySlice";
import {
  selectInformation,
  selectMoney,
} from "../../../../../Stores/informationSlice";
import { addToCart, selectCart } from "../../../../../Stores/redisCartSlice";
import Loading from "../../../../Loading/Loading";

import { setProductOptionModal } from "../../../../../Stores/commonSlice";
import { ProductItemsStyles } from "./ProductItemsStyles";

interface IProps {
  mainProduct: IMainProduct;
}

const ProductItemWithRedisCart: React.FC<IProps> = ({ mainProduct }) => {
  const classes = ProductItemsStyles();
  const { t } = useTranslation();

  // =======================useSelector=======================
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const money = useSelector(selectMoney);
  const delivery = useSelector(selectDelivery);
  const information = useSelector(selectInformation);

  // =======================useState=========================
  const [loading, setLoading] = useState(false);

  // =========================values==========================
  const storeId = GetStoreId();
  const cartId = cart?.userCart?.id ? cart.userCart.id : undefined;

  // =========================methods=========================
  const checkDiscount = (item: ITierPrice) => {
    // Check Offers Date & Offer is enable
    const startDate = item.startDateTime;
    const endDate = item.endDateTime;
    const today = new Date();
    if (
      startDate &&
      endDate &&
      today > new Date(startDate) &&
      today < new Date(endDate) &&
      information.allowOffers
    )
      return true;
    else return false;
  };

  const handleAddToCart = async () => {
    if (information.openOrder || information.preOrder) {
      if (storeId) {
        let requestCart: ICartRequest = {
          id: cartId,
          storeId: storeId,
          delivery: delivery?.delivery,
          isFreebie: false,
          productId: mainProduct.defaultProductId,
          duplicateNum: 0,
          quantity: 1,
          note: "",
          editable: mainProduct.requiredOptions,
          postCode: delivery?.postCode,
          attributes: [],
        };
        setLoading(true);
        await agent.Cart.post(requestCart)
          .then((res: ICart) => {
            if (res) {
              dispatch(addToCart(res));
            } else {
              toast.error(t("somethingWentWrongTryAgain"));
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => setLoading(false));
      }
    } else toast.warn(t("onlineOrderClosed"));
  };

  if (loading) return <Loading />;
  // ==========================tsx============================
  return (
    <>
      <Grid container className={classes.productItems2}>
        <Grid item xs={12} md={9} className="naming">
          <span className="name">{mainProduct.name}</span>
          <div className="desc">
            <ShowMoreText
              lines={1}
              more={
                <IconButton color="primary">
                  <ExpandMoreRoundedIcon />
                </IconButton>
              }
              less={
                <IconButton color="secondary">
                  <ExpandLessRoundedIcon />
                </IconButton>
              }
              className="contentStyle"
              anchorClass="anchorStyle"
              expanded={false}
              width={0}
            >
              <div
                dangerouslySetInnerHTML={{ __html: mainProduct.description }}
              />
            </ShowMoreText>
          </div>
          <div className={classes.discounts}>
            <span className={classes.discount}>
              {mainProduct.products.length > 0 &&
                mainProduct.products
                  .find((item) => item.tierPrices?.length > 0)
                  ?.tierPrices.filter((item) => checkDiscount(item))[0]?.title}
            </span>
          </div>
        </Grid>
        <Grid item xs={12} md={3} className="ordering">
          <span className="price">
            {mainProduct.defaultProductPrice > 0 &&
              money(mainProduct.defaultProductPrice)}
          </span>
          {!mainProduct.requiredOptions ? (
            <IconButton
              size="small"
              onClick={handleAddToCart}
              className={classes.addToCartBtn}
            >
              {/* Add To Cart */}
              <div className="whiteBg" />
              <AddCircleRoundedIcon fontSize="large" />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={() =>
                dispatch(
                  setProductOptionModal({
                    open: true,
                    mainProductId: mainProduct.id,
                    productId: "",
                    duplicateNumber: 0,
                  })
                )
              }
              className={classes.optionBtn}
            >
              {/* Required Options */}
              <div className="whiteBg" />
              <AddCircleRoundedIcon fontSize="large" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ProductItemWithRedisCart;
