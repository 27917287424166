import { IUser, IUserSession } from "../Models/user";

export function isNumeric(value: any) {
  return /^-{0,1}\d+$/.test(value);
}
export function getUser(): IUser {
  let stringValue = window.localStorage.getItem("account");
  if (stringValue !== null) {
    let value: IUserSession = JSON.parse(stringValue);
    let expirationDate = new Date(value.date);
    if (expirationDate > new Date()) {
      return value.user;
    } else {
      window.localStorage.removeItem("account");
    }
  }
  return {
    firstName: "",
    lastName: "",
    guest: false,
    tableOrder: false,
    roles: [],
    token: "",
  };
}
export class EnumHelpers {
  static getNamesAndValues<T extends number>(e: any) {
    return EnumHelpers.getNames(e).map((n) => ({ name: n, value: e[n] as T }));
  }

  static getNames(e: any) {
    return EnumHelpers.getObjValues(e).filter(
      (v) => typeof v === "string"
    ) as string[];
  }

  static getValues<T extends string>(e: any) {
    return EnumHelpers.getObjValues(e).filter(
      (v) => typeof v === "string"
    ) as T[];
  }

  static getSelectList<T extends string>(e: any) {
    const selectList = new Map<T, string>();
    this.getValues(e).forEach((val) => selectList.set(val as T, val as string));
    return selectList;
  }

  static getSelectListAsArray<T extends string>(e: any) {
    return Array.from(this.getSelectList(e), (value) => ({
      id: value[0] as T,
      text: value[1],
    }));
  }

  private static getObjValues(e: any): (number | string)[] {
    return Object.keys(e).map((k) => e[k]);
  }
}

export const getLocalStorage = (key: string) => {
  let value =
    localStorage.getItem(key) && localStorage.getItem(key) !== "undefined"
      ? localStorage.getItem(key)
      : null;
  return value !== null ? JSON.parse(value) : null;
};

export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const getHtmlTextContent = (text: string) => {
  const el = document.createElement("div");
  el.innerHTML = text;
  return el.textContent;
};
