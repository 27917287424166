import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const FooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      paddingTop: 15,
      backgroundColor: theme.palette.footerBg.main,
      color: "#fff",
      zIndex: 2,

      "& a": {
        color: "#fff",
      },
    },
    socialLinks: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& a": {
        display: "block",
        width: 35,
        height: 35,
        margin: 5,
        padding: 5,

        borderRadius: 100,
        backgroundColor: theme.palette.primary.light,
      },
    },

    paymentCarts: {
      display: "flex",
      justifyContent: "center",
      padding: "10px 0",

      [theme.breakpoints.down("xs")]: {
        height: 70,
      },
      [theme.breakpoints.up("sm")]: {
        height: 45,
      },
      [theme.breakpoints.up("md")]: {
        height: 52,
      },
      [theme.breakpoints.up("lg")]: {
        height: 41,
      },
      [theme.breakpoints.up("xl")]: {
        justifyContent: "flex-end",
        height: 51,
        paddingRight: 180,
      },

      "& img": {
        objectFit: "contain",
        marginRight: 10,
        backgroundColor: "#fff",
        borderRadius: 4,
        height: 25,

        [theme.breakpoints.down("xs")]: {
          margin: "10px 5px",
        },
        [theme.breakpoints.up("sm")]: {},
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {},
        [theme.breakpoints.up("xl")]: {},
      },
    },

    contactUs: {
      padding: "10px 30px !important",

      "& h6": {},
    },

    copyRight: {
      padding: 10,
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.grey[400],
    },
    logo: {
      padding: 20,
      "& img": {
        width: 100,
      },
    },
  })
);
