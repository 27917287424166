import {
  Button,
  ButtonGroup,
  Grid,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { combineValidators, composeValidators, isRequired } from "revalidate";
import { history } from "../../..";
import { IAddAddress, IGuestRegister } from "../../../Models/guest";
import { IPagedCollection } from "../../../Models/pagedCollection";
import { SelectList } from "../../../Models/selectList";
import { isValidEmail } from "../../../Services/CustomValidate";
import agent from "../../../Services/agent";
import { selectDelivery, setDelivery } from "../../../Stores/deliverySlice";
import { selectCart } from "../../../Stores/redisCartSlice";
import { setToken } from "../../../Stores/userSlice";
import ErrorMessage from "../../../utils/ErrorMessage";
import CheckboxUtil from "../../../utils/MaterialUi/CheckboxUtil";
import SelectInput from "../../../utils/SelectInput";
import TextInput from "../../../utils/TextInput";
import GenericBtn from "../../Common/GenericBtn/GenericBtn";
import Loading from "../../Loading/Loading";
import AddAddress from "./AddAddress/AddAddress";
import { GuestRegisterStyles } from "./GuestRegisterStyles";

const GuestRegister = () => {
  const dispatch = useDispatch();
  const delivery = useSelector(selectDelivery);
  const cart = useSelector(selectCart);
  const [loading, setLoading] = useState(false);
  const [canDelivery, setCanDelivery] = useState(false);
  const [showAddressList, setShowAddressList] = useState(false);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [addAddress, setAddAddress] = useState<IAddAddress>({
    street: "",
    town: "",
    area: "",
    address1: "",
  });
  const [addressList, setAddressList] = useState<
    IPagedCollection<SelectList<string>>
  >({
    limit: 0,
    offset: 0,
    size: 0,
    value: [],
  });
  const { t } = useTranslation();
  const classes = GuestRegisterStyles();

  const validate = combineValidators({
    email: composeValidators(
      isValidEmail(),
      isRequired({ message: t("required") })
    )(),
    fullName: isRequired({ message: t("required") }),
    phoneNumber: isRequired({ message: t("required") }),
  });

  const guestRegisterForm: IGuestRegister = {
    email: "",
    postCode: delivery.postCode,
    addressId: null,
    addAddress: addAddress,
    fullName: "",
    register: false,
    password: "",
    phoneNumber: "",
    cartId: cart?.userCart?.id,
    isCollection: !delivery.delivery,
  };

  const getAddresses = async (postcode: string) => {
    setLoading(true);
    await agent.PostCode.postCodeSelectList(postcode, 0, 100)
      .then((res) => {
        setAddressList(res);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(t("cantFindAddress"));
        setLoading(false);
      });
  };

  const HandleSubmit = async (guestRegisterForm: IGuestRegister) => {
    if (
      delivery.delivery &&
      guestRegisterForm.addressId == null &&
      guestRegisterForm.addAddress.street === ""
    ) {
      toast.error(t("clickOnGetAddresses"));
      return false;
    }

    await agent.Order.CreateGuestOrder(guestRegisterForm)
      .then((res) => {
        dispatch(setToken(res));
        history.push(`/store/payment/${res.orderId}`);
      })
      .catch((error) => {
        toast.error(t("somethingWentWrongTryAgain"));
      });
  };
  const HandleAddAddress = (addAddress: IAddAddress) => {
    setAddAddress(addAddress);
    setAddAddressModal(false);
    setShowAddressList(false);
  };

  const CallCanDelivery = async () => {
    await agent.DeliveryTimes.canDelivery().then((res) => {
      if (!res) {
        setDelivery({
          delivery: false,
          deliveryCharge: 0,
          minimumOrder: 0,
          postCode: "",
        });
      }
      setCanDelivery(res);
    });
  };

  useEffect(() => {
    CallCanDelivery();
    if (delivery.postCode.length > 5 && canDelivery)
      getAddresses(delivery.postCode);
  }, [canDelivery, delivery.postCode]);

  return (
    <Grid container justifyContent="center">
      {loading && <Loading />}
      <AddAddress
        addAddressModal={addAddressModal}
        setAddAddressModal={setAddAddressModal}
        addAddress={guestRegisterForm.addAddress}
        handleSubmit={HandleAddAddress}
      />
      <Grid item xs={12} md={6} xl={4}>
        <Paper className={classes.root}>
          <Form
            validate={validate}
            initialValues={guestRegisterForm}
            onSubmit={HandleSubmit}
            keepDirtyOnReinitialize
            render={({
              handleSubmit,
              invalid,
              pristine,
              submitting,
              form,
              values,
              submitError,
              dirtySinceLastSubmit,
            }) => (
              <Grid
                container
                spacing={1}
                component="form"
                onSubmit={handleSubmit}
              >
                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage text={t("error(s)")} error={submitError} />
                )}
                <Grid item xs={12}>
                  <Typography variant="h5">Order as a guest</Typography>
                  <Typography variant="body2">
                    You can order as a guest or&nbsp;
                    <Link
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (delivery.delivery)
                          localStorage.setItem("pushPath", "/address");
                        else
                          localStorage.setItem(
                            "pushPath",
                            "/store/ordering/departments"
                          );
                        history.push("/store/login");
                      }}
                    >
                      {t("login")}
                    </Link>
                    &nbsp;to your existing account to speed up checkout.
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    label={t("fullName")}
                    name="fullName"
                    component={TextInput}
                    placeholder={t("fullName")}
                    required
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    label={t("phoneNumber")}
                    name="phoneNumber"
                    component={TextInput}
                    placeholder={t("phoneNumber")}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label={t("emailAddress")}
                    autoFocus
                    name="email"
                    component={TextInput}
                    placeholder={t("emailAddress")}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonGroup color="primary">
                    {canDelivery && (
                      <GenericBtn
                        disabled
                        onClick={() => {
                          dispatch(
                            setDelivery({
                              delivery: true,
                              postCode: "",
                              deliveryCharge: 0,
                              minimumOrder: 0,
                            })
                          );
                        }}
                        variant={delivery.delivery ? "contained" : "outlined"}
                        style={{
                          margin: 0,
                          borderRadius: canDelivery ? "15px 0 0 15px" : "unset",
                        }}
                      >
                        {t("delivery")}
                      </GenericBtn>
                    )}
                    <GenericBtn
                      disabled
                      onClick={() => {
                        dispatch(
                          setDelivery({
                            delivery: false,
                            postCode: "",
                            deliveryCharge: 0,
                            minimumOrder: 0,
                          })
                        );
                      }}
                      variant={delivery.delivery ? "outlined" : "contained"}
                      style={{
                        margin: 0,
                        borderRadius: canDelivery ? "0 15px 15px 0" : "unset",
                      }}
                    >
                      {t("collection")}
                    </GenericBtn>
                  </ButtonGroup>
                </Grid>
                {delivery.delivery && (
                  <Grid item xs={12}>
                    <Grid item xs={12} container>
                      <Grid item xs={12} sm={8}>
                        <Field
                          disable={true}
                          label={t("postCode")}
                          name="postCode"
                          component={TextInput}
                          validate={
                            delivery.delivery &&
                            isRequired({
                              message: t("required"),
                            })
                          }
                          placeholder={t("postCode")}
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          type="button"
                          color="primary"
                          onClick={() => {
                            if (values.postCode.length < 1) {
                              toast.error(t("yourPostCodeIsWrong"));
                            } else {
                              getAddresses(values.postCode);
                              setShowAddressList(true);
                            }
                          }}
                        >
                          {t("getAddresses")}
                        </Button>
                      </Grid>
                    </Grid>
                    {addressList.size > 0 && showAddressList && (
                      <Field
                        label={t("addresses")}
                        name="addressId"
                        component={SelectInput}
                        placeholder={t("addresses")}
                        options={addressList.value}
                      />
                    )}
                    {addressList.size > 0 && showAddressList && (
                      <p>
                        {t("ifYourAddressNotListed")},&nbsp;
                        <Link
                          color="primary"
                          type="button"
                          onClick={() => {
                            setAddAddressModal(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {t("addYourAddress")}
                        </Link>
                        .
                      </p>
                    )}
                    {addAddress.street.length > 0 && (
                      <p>
                        <span style={{ fontWeight: 600 }}>
                          {t("yourAddress")}:&nbsp;
                        </span>
                        {addAddress.street},&nbsp;
                        {addAddress.town},&nbsp;
                        {addAddress.area},&nbsp;
                        {addAddress.address1}.&nbsp;
                        <span>
                          <Link
                            component="button"
                            color="secondary"
                            type="button"
                            onClick={() => {
                              setAddAddressModal(true);
                            }}
                            style={{ fontWeight: 600 }}
                          >
                            {t("edit")}
                          </Link>
                        </span>
                      </p>
                    )}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    label="Save your details for faster checkout on your next visit."
                    name="register"
                    component={CheckboxUtil}
                    type="checkbox"
                    placeholder="Save your details for faster checkout on your next visit."
                  />
                  {values.register && (
                    <Fragment>
                      <Field
                        label={t("password")}
                        name="password"
                        component={TextInput}
                        validate={
                          values.register &&
                          isRequired({ message: t("required") })
                        }
                        type="password"
                        placeholder={t("password")}
                        required
                      />
                    </Fragment>
                  )}
                </Grid>

                <Grid item xs={12} container justifyContent="flex-end">
                  <GenericBtn
                    type="submit"
                    disabled={(invalid && !dirtySinceLastSubmit) || submitting}
                    variant="contained"
                    color="secondary"
                  >
                    {t("next")}
                  </GenericBtn>
                </Grid>
              </Grid>
            )}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GuestRegister;
