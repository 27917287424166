import React from "react";
import "./simpleArrow.scss";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
const SampleNextArrow = (props: any) => {
  const { style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        border: "2px solid #fff",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 40,
        width: 40,
        height: 40,
        cursor: 'pointer'
      }}
      onClick={() => onClick()}
      className="simpleArrow next"
    >
      <ArrowForwardIosRoundedIcon />
    </div>
  );
};

export default SampleNextArrow;
