import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import LocationSearchingRoundedIcon from "@material-ui/icons/LocationSearchingRounded";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { history } from "../..";
import { ICart } from "../../Models/Cart/ICart";
import { IChangeDelivery } from "../../Models/Cart/IChangeDelivery";
import agent from "../../Services/agent";
import { GetStoreId } from "../../Services/storeSession";
import { selectDelivery, setDelivery } from "../../Stores/deliverySlice";
import { selectInformation } from "../../Stores/informationSlice";
import { addToCart, selectCart } from "../../Stores/redisCartSlice";
import Loading from "../Loading/Loading";
import { DeliveryCollectionStyles } from "./DeliveryCollectionModalStyles";

interface IProps {
  open: boolean;
  handleClose(): void;
  preOrder: boolean;
  deliverySelected?: boolean;
}

const DeliveryCollectionModal: React.FC<IProps> = ({
  open,
  handleClose,
  preOrder,
  deliverySelected,
}) => {
  const classes = DeliveryCollectionStyles();
  const { t } = useTranslation();

  // =======================useSelector=======================
  const dispatch = useDispatch();
  const delivery = useSelector(selectDelivery);
  const cart = useSelector(selectCart);

  // ========================useState=========================
  const [canDelivery, setCanDelivery] = useState(preOrder);
  const information = useSelector(selectInformation);
  const [postCode, setPostCode] = useState(delivery.postCode);
  const [messageText, setMessageText] = useState("");
  const [messageType, setMessageType] = useState(Boolean);
  const scroll: DialogProps["scroll"] = "paper";
  const [value, setValue] = React.useState(
    delivery.delivery || deliverySelected ? "delivery" : "collection"
  );
  const [loading, setLoading] = useState(false);

  // =========================values==========================
  const storeId = GetStoreId();
  const cartId = cart?.userCart?.id ? cart.userCart.id : undefined;

  // =========================methods=========================
  const CallCanDelivery = async () => {
    setLoading(true);
    await agent.DeliveryTimes.canDelivery()
      .then((res) => {
        if (!res) {
          setDelivery({
            delivery: false,
            deliveryCharge: 0,
            minimumOrder: 0,
            postCode: "",
          });
          setValue("collection");
        }
        setCanDelivery(res);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => addressValidateEn();

  const changeDeliveryHandler = async (
    model: IChangeDelivery
  ): Promise<number | null> => {
    setLoading(true);
    const result = await agent.Cart.post({
      attributes: [],
      delivery: model.delivery,
      duplicateNum: 0,
      editable: false,
      isFreebie: false,
      note: "",
      postCode: model.postCode,
      productId: undefined,
      quantity: 0,
      storeId: model.storeId,
      id: model.id,
    })
      .then((res: ICart) => {
        setLoading(false);
        if (res) {
          dispatch(addToCart(res));
          return res.userCart?.deliveryCharge;
        } else {
          toast.error(t("somethingWentWrongTryAgain"));
          return null;
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(t("somethingWentWrongTryAgain"));
        return null;
      });
    return result;
  };

  const collectionChangedHandler = () => {
    if (!!cartId && !!storeId) {
      changeDeliveryHandler({
        id: cartId,
        storeId: storeId,
        delivery: false,
        postCode: "",
      }).then((deliveryChargeResult) => {
        if (deliveryChargeResult !== null) {
          setMessageType(false);
          setMessageText("");
          setValue("collection");
          dispatch(
            setDelivery({
              delivery: false,
              postCode: "",
              deliveryCharge: 0,
              minimumOrder: 0,
            })
          );
        }
      });
    } else if (!cartId) {
      setMessageType(false);
      setMessageText("");
      setValue("collection");
      dispatch(
        setDelivery({
          delivery: false,
          postCode: "",
          deliveryCharge: 0,
          minimumOrder: 0,
        })
      );
    }
  };

  const addressValidateEn = () => {
    setLoading(true);
    agent.DeliveryCharges.value(postCode)
      .then((res) => {
        if (res.canDelivery) {
          setMessageText(
            t(
              "yourPostcodeIsWithinOurOnlineOrderingRegistrationAreaAndWeDeliverToYourAddress"
            )
          );
          setMessageType(true);
          if (!!cartId && !!storeId) {
            changeDeliveryHandler({
              id: cartId,
              storeId: storeId,
              delivery: true,
              postCode: postCode,
            }).then((deliveryChargeResult) => {
              if (
                deliveryChargeResult !== null &&
                deliveryChargeResult !== undefined
              ) {
                dispatch(
                  setDelivery({
                    delivery: true,
                    postCode: postCode,
                    deliveryCharge: res.deliveryCharge,
                    minimumOrder: res.minimumOrder,
                  })
                );
              } else {
                toast.error(t("errorInDeliveryChargeCart"));
              }
            });
          } else if (!cartId) {
            dispatch(
              setDelivery({
                delivery: true,
                postCode: postCode,
                deliveryCharge: res.deliveryCharge,
                minimumOrder: res.minimumOrder,
              })
            );
          }
        } else {
          setMessageText(t("youJustCanCollectionYourOrder"));
          dispatch(
            setDelivery({
              delivery: false,
              postCode: postCode,
              deliveryCharge: res.deliveryCharge,
              minimumOrder: res.minimumOrder,
            })
          );
          setMessageType(false);
        }
      })
      .catch(() => {
        setMessageText(
          t("yourPostCodeIsWrongAndYouJustCanCollectionYourOrder")
        );
        setMessageType(false);
        dispatch(
          setDelivery({
            delivery: false,
            postCode: postCode,
            deliveryCharge: 0,
            minimumOrder: 0,
          })
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    CallCanDelivery();
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        {loading && <Loading />}
        <DialogContent
          style={{
            backgroundColor: information.darkTheme ? "#424242" : "#fff",
          }}
          dividers={scroll === "paper"}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                {t("howWouldYouLikeToOrder")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ButtonGroup color="primary" className={classes.btnGroup}>
                {canDelivery && (
                  <Button
                    className={information.darkTheme ? "whiteColorBtn" : ""}
                    onClick={() => setValue("delivery")}
                    variant={value === "delivery" ? "contained" : "outlined"}
                  >
                    {t("delivery")}
                  </Button>
                )}

                <Button
                  className={information.darkTheme ? "whiteColorBtn" : ""}
                  onClick={collectionChangedHandler}
                  variant={value === "collection" ? "contained" : "outlined"}
                >
                  {t("collection")}
                </Button>
              </ButtonGroup>
            </Grid>
            {value === "delivery" ? (
              <Grid
                item
                xs={12}
                container
                className={classes.postCode}
                spacing={2}
              >
                {information.langDisplay === "en-GB" && (
                  <Grid item xs={12} sm={8} className={classes.enterPostCode}>
                    <TextField
                      id="outlined-helperText"
                      label={t("postCode")}
                      variant="outlined"
                      size="small"
                      value={postCode}
                      onChange={(e) => setPostCode(e.target.value)}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={4} className={classes.enterPostCode}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    color="primary"
                  >
                    <LocationSearchingRoundedIcon
                      fontSize="small"
                      htmlColor="#ffffff"
                    />
                    &nbsp;
                    {t("findMe")}
                  </Button>
                </Grid>
                {messageText !== "" && (
                  <Grid item xs={12} className={classes.postCodeResult}>
                    <Divider />
                    {information.darkTheme ? (
                      <h5>{messageText}</h5>
                    ) : (
                      <h5
                        style={{
                          color: messageType ? "green" : "red",
                        }}
                      >
                        {messageText}
                      </h5>
                    )}
                  </Grid>
                )}
                {messageType && (
                  <Grid item xs={12} container>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleClose();
                        history.push("/store/ordering/departments");
                      }}
                    >
                      {t("orderNow")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            ) : (
              <>
                <Grid item xs={12} container justifyContent="center">
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("youCanCollectionYourOrder")}
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      handleClose();
                      history.push("/store/ordering/departments");
                    }}
                  >
                    {t("orderNow")}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeliveryCollectionModal;
