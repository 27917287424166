import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { history } from "../../..";
import { IOrderDetail } from "../../../Models/order";
import agent from "../../../Services/agent";
import { dateFormat, selectMoney } from "../../../Stores/informationSlice";
import Loading from "../../Loading/Loading";
import { ProfileInvoiceStyles } from "./ProfileInvoiceStyles";

interface IProps {
  match: any;
}
const ProfileInvoice: React.FC<IProps> = ({ match }) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<IOrderDetail>();
  const id = match.params.id;
  const classes = ProfileInvoiceStyles();
  const money = useSelector(selectMoney);
  const formatter = useSelector(dateFormat);
  const [loading, setLoading] = useState(false);

  const getOrder = useCallback(async () => {
    setLoading(true);
    await agent.Order.detail(id)
      .then((res) => setOrder(res))
      .catch(() => toast.error("somethingWentWrong"))
      .finally(() => setLoading(false));
  }, [id]);

  const reorder = () => {
    if (order) {
      setLoading(true);
      agent.Order.reorder({ orderId: order.id })
        .then((res) => history.push(`/store/payment/${res}`))
        .catch(() => toast.error("somethingWentWrong"))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  if (!order || loading) return <Loading />;

  return (
    <Grid container className={classes.profileInvoice}>
      <Grid item xs={12} className="back">
        <Link to="/store/profile/orders">
          <ArrowBackRoundedIcon />
        </Link>
      </Grid>
      <Grid item xs={12} md={8}>
        <h5>
          {t("orderNo")}:&nbsp;
          <span>{order.customOrderNumber}</span>
        </h5>
        <h5>
          {t("deliver")}:&nbsp;
          <span>{t(order.shippingStatusName)}</span>
        </h5>
        <h5>
          {t("billingAddress")}:&nbsp;
          <br />
          <span>
            {t("street")}:&nbsp;
            {order.billingAddress.street}
          </span>
          <br />
          <span>
            {t("area")}:&nbsp;
            {order.billingAddress.area}
          </span>
          <br />
          <span>
            {t("town")}:&nbsp;
            {order.billingAddress.town}
          </span>
          <br />
          <span>
            {t("phoneNumber")}:&nbsp;
            {order.billingAddress.phoneNumber}
          </span>
          <br />
          <span>
            {t("postCode")}:&nbsp;
            {order.billingAddress.zipPostalCode}
          </span>
        </h5>
      </Grid>
      <Grid item xs={12} md={4}>
        <h5>
          {t("orderDate")}:&nbsp;
          <span>{formatter.format(new Date(order.createdOn))}</span>
        </h5>
        <h5>
          {t("clientName")}:&nbsp;
          <span>{order.billingAddress.fullName}</span>
        </h5>
        <h5>
          {order.pickupInStore ? t("collectionTime") : t("deliveryTime")}:&nbsp;
          <span>
            {order.deliveryTime === null
              ? t("asap")
              : order.deliveryTime.split("T", 2)[1].slice(0, 5)}
          </span>
        </h5>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Button
          disabled={order.orderStatusId === "Complete"}
          color="secondary"
          variant="contained"
          onClick={reorder}
        >
          {t("preOrder")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <hr />
        <TableContainer
          component={Paper}
          elevation={1}
          className="productTable"
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t("productImage")}</TableCell>
                <TableCell>{t("product")}</TableCell>
                <TableCell>{t("price")}</TableCell>
                <TableCell align="center">{t("qty")}</TableCell>
                <TableCell>{t("discount")}</TableCell>
                <TableCell align="right">{t("total")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.orderItems.map((item) => (
                <TableRow hover key={item.id}>
                  <TableCell>
                    <img
                      src={`${process.env.REACT_APP_IMG_URL}${item.picPath}`}
                      alt={item.productName}
                    />
                  </TableCell>
                  <TableCell>
                    {item.productName}
                    <ul className="extra">
                      {item.orderItemProductAttributeValueMappings.map(
                        (att) => (
                          <li key={att.id}>{att.productAttributeValue.name}</li>
                        )
                      )}
                    </ul>
                  </TableCell>
                  <TableCell align="center">
                    <ul className="extraPrice">
                      {money(item.price)}
                      {item.orderItemProductAttributeValueMappings.map(
                        (att) => (
                          <li key={att.id}>
                            <span>
                              {money(att.productAttributeValuePriceAdjustment)}
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  </TableCell>
                  <TableCell align="center">{item.quantity}</TableCell>
                  <TableCell>{item.discountTitle}</TableCell>
                  <TableCell align="right" className={classes.price}>
                    <span className={item.discount > 0 ? "discount" : ""}>
                      {money(
                        item.orderItemProductAttributeValueMappings
                          .map(
                            (attr) => attr.productAttributeValuePriceAdjustment
                          )
                          .reduce((prev, curr) => prev + curr, 0) *
                          item.quantity +
                          item.price * item.quantity
                      )}
                    </span>
                    {item.discount > 0 && (
                      <span>
                        {money(
                          item.orderItemProductAttributeValueMappings
                            .map(
                              (attr) =>
                                attr.productAttributeValuePriceAdjustment
                            )
                            .reduce((prev, curr) => prev + curr, 0) *
                            item.quantity +
                            item.price * item.quantity -
                            item.discount
                        )}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Table className={classes.totals}>
            <TableRow className="bold fs1rem">
              <TableCell>{t("subTotal")}</TableCell>
              <TableCell align="right">{money(order.orderTotal)}</TableCell>
            </TableRow>
            <TableRow className="bold fs1rem">
              <TableCell>{t("delivery")}</TableCell>
              <TableCell align="right">{money(order.deliveryCharge)}</TableCell>
            </TableRow>
            {order.serviceCharge > 0 && (
              <TableRow className="bold fs1rem">
                <TableCell>{t("serviceCharge")}</TableCell>
                <TableCell align="right">
                  {money(order.serviceCharge)}
                </TableCell>
              </TableRow>
            )}
            <TableRow className="bold fs1rem discount">
              <TableCell>{t("discount")}</TableCell>
              <TableCell align="right">{money(order.discount)}</TableCell>
            </TableRow>
            <TableRow className="bold fs1rem">
              <TableCell>{t("total")}</TableCell>
              <TableCell align="right">
                {money(
                  order.orderTotal + order.deliveryCharge - order.discount
                )}
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ProfileInvoice;
