import {
  Button,
  Grid,
  Hidden,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { history } from "../../..";
import { IImageWebSite } from "../../../Models/ImageWebSite";
import { ITextSlider, TextSliderType } from "../../../Models/textSlider";
import agent from "../../../Services/agent";
import { selectInformation } from "../../../Stores/informationSlice";
import CustomPaging from "../../Common/CustomPaging/CustomPaging";
import GenericBtn from "../../Common/GenericBtn/GenericBtn";
import CustomSlider from "../../Slider/Slider";

import SamplePrevArrow from "../../Common/SimpleArrow/SimpleBackArrow";
import SampleNextArrow from "../../Common/SimpleArrow/SimpleNextArrow";
import TemporaryShopClose from "../../TemporaryShopClose/TemporaryShopClose";
import Carousel2 from "../Carousel2/Carousel2";
import { RestaurantLandingPageStyles } from "./RestaurantLandingPageStyles";
const RestaurantLandingPage = () => {
  const classes = RestaurantLandingPageStyles();
  const { t } = useTranslation();
  const [images, setImages] = useState<IImageWebSite[]>([]);
  const [sliders, setSliders] = useState<ITextSlider[]>([]);

  const information = useSelector(selectInformation);
  const getLandingPageImages = async () => {
    await agent.ImageWebSite.getLandingPageImages().then((res) => {
      setImages(res);
    });
  };
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    row: 1,
    customPaging: CustomPaging,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    initialSlide: 0,
    // className: "carouselHeight",
    fade: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const getSliders = () => {
    agent.TextSlider.get().then((res) => setSliders(res));
  };
  useEffect(() => {
    getLandingPageImages();
    getSliders();
  }, []);
  return (
    <>
      {information.temporaryShopClose && (
        <TemporaryShopClose
          temporaryShopCloseText={information.temporaryShopCloseText}
        />
      )}
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          height: "100%",
          filter: information.darkTheme ? "brightness(0.5)" : "opacity(60%)",
          zIndex: -1,
          backgroundColor: information.backGroundColor,
        }}
      >
        <Carousel2 />
      </div>
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Grid item xs={12}>
          <CustomSlider
            sliders={sliders.filter((a) => a.type === TextSliderType.Simple)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={9}
          lg={8}
          xl={8}
          container
          className={classes.root}
        >
          <Grid item xs={12} className="info">
            {information.title !== null &&
            information.title.length > 0 &&
            information.title.startsWith("<") ? (
              <>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.title }}
                ></div>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.description }}
                ></div>
                <div
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.board }}
                ></div>
              </>
            ) : (
              <>
                <h2>{t("welcomeTo")}</h2>
                <h1>
                  {information.title ? information.title : information.name}
                </h1>
                <h4 className="services">{information.description}</h4>
                <p
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: information.board }}
                ></p>
              </>
            )}
            {information.storeType === "Restaurant" &&
              information.bookingTable && (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => history.push("/store/table-reserve")}
                  >
                    {t("reserveTable")}
                  </Button>
                  <GenericBtn
                    label={t("tableOrder")}
                    onClick={() => history.push("/store/tableOrdering")}
                    size="large"
                    variant="outlined"
                  />
                </>
              )}
            {!information.openOrder && information.preOrder && (
              <p className={classes.preOrder}>
                Note:&nbsp;
                <span>Online ordering is closed</span>, You can submit your
                order as pre-order and get it when online ordering is opened.
              </p>
            )}
            {information.storeType === "Restaurant" && (
              <div className={classes.action}>
                <Grid item xs={12}>
                  {sliders
                    ?.filter((a) => a.type === TextSliderType.LandingPage)
                    .map((a) => (
                      <div
                        dangerouslySetInnerHTML={{ __html: a.text }}
                        key={a.id}
                      />
                    ))}
                </Grid>
                {(images?.filter((a) => a.type === "Button")).length > 0 && (
                  <Grid container justifyContent="center">
                    <Grid item lg={8} xs={12} sm={10} md={9}>
                      <Paper elevation={1} className="menus">
                        <Typography
                          variant="h3"
                          component="h4"
                          color="textPrimary"
                          align="center"
                        >
                          {t("menus")}
                        </Typography>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                        >
                          {images
                            ?.filter((a) => a.type === "Button")
                            .sort((a, b) => a.displayOrder - b.displayOrder)
                            .map((a) => (
                              <Grid
                                key={a.id}
                                item
                                xl={6}
                                xs={6}
                                sm={4}
                                md={2}
                                lg={2}
                              >
                                <Grid container justifyContent="center">
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    target="_blank"
                                    href={`${process.env.REACT_APP_IMG_URL}${a.picPath}`}
                                  >
                                    {a.title}
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
                {images.filter((a) => a.type === "FoodsGallery").length > 0 && (
                  <Grid
                    container
                    justifyContent="center"
                    className={classes.imageRoot}
                  >
                    <Hidden only={["sm", "md", "lg", "xl"]}>
                      <Paper variant="outlined" className={classes.gridxs}>
                        <Grid item xs={12}>
                          <Slider {...settings}>
                            {images
                              .filter((a) => a.type === "FoodsGallery")
                              .sort((a, b) => a.displayOrder - b.displayOrder)
                              ?.map((item) => (
                                <img
                                  key={item.id}
                                  src={`${process.env.REACT_APP_IMG_URL}${item.picPath}`}
                                  alt={item.title}
                                  height={200}
                                />
                              ))}
                          </Slider>
                        </Grid>
                      </Paper>
                    </Hidden>
                    <Hidden only={["xs", "md", "lg", "xl"]}>
                      <Paper variant="outlined" className={classes.gridsm}>
                        <Grid item xs={12}>
                          <Slider {...settings}>
                            {images
                              .filter((a) => a.type === "FoodsGallery")
                              .sort((a, b) => a.displayOrder - b.displayOrder)
                              ?.map((item) => (
                                <img
                                  key={item.id}
                                  src={`${process.env.REACT_APP_IMG_URL}${item.picPath}`}
                                  alt={item.title}
                                  height={300}
                                />
                              ))}
                          </Slider>
                        </Grid>
                      </Paper>
                    </Hidden>
                    <Hidden only={["xs", "sm", "lg", "xl"]}>
                      <Paper variant="outlined" className={classes.gridmd}>
                        <Grid item xs={12}>
                          <Slider {...settings}>
                            {images
                              .filter((a) => a.type === "FoodsGallery")
                              .sort((a, b) => a.displayOrder - b.displayOrder)
                              ?.map((item) => (
                                <img
                                  key={item.id}
                                  src={`${process.env.REACT_APP_IMG_URL}${item.picPath}`}
                                  alt={item.title}
                                  height={350}
                                />
                              ))}
                          </Slider>
                        </Grid>
                      </Paper>
                    </Hidden>
                    <Hidden only={["xs", "sm", "md", "xl"]}>
                      <ImageList rowHeight={300} gap={2} cols={3}>
                        {images
                          .filter((a) => a.type === "FoodsGallery")
                          .sort((a, b) => a.displayOrder - b.displayOrder)
                          ?.map((item) => (
                            <ImageListItem
                              key={`${process.env.REACT_APP_IMG_URL}${item.picPath}`}
                              cols={item.cols < 1 ? 1 : item.cols}
                              rows={item.rows < 1 ? 1 : item.rows}
                            >
                              <img
                                src={`${process.env.REACT_APP_IMG_URL}${item.picPath}`}
                                alt={item.title}
                              />
                            </ImageListItem>
                          ))}
                      </ImageList>
                    </Hidden>
                    <Hidden only={["xs", "sm", "md", "lg"]}>
                      <ImageList rowHeight={300} gap={2} cols={3}>
                        {images
                          .filter((a) => a.type === "FoodsGallery")
                          .sort((a, b) => a.displayOrder - b.displayOrder)
                          ?.map((item) => (
                            <ImageListItem
                              key={`${process.env.REACT_APP_IMG_URL}${item.picPath}`}
                              cols={item.cols < 1 ? 1 : item.cols}
                              rows={item.rows < 1 ? 1 : item.rows}
                            >
                              <img
                                src={`${process.env.REACT_APP_IMG_URL}${item.picPath}`}
                                alt={item.title}
                              />
                            </ImageListItem>
                          ))}
                      </ImageList>
                    </Hidden>
                  </Grid>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RestaurantLandingPage;
