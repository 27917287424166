import React from "react";
import { Button, Grid } from "@material-ui/core";
import TextInput from "../../utils/TextInput";
import { Field, Form } from "react-final-form";
import { IWorldPay } from "../../Models/worldPay";
import NumberFormatCustom from "../../utils/NumberFormatCustom";
import { useTranslation } from "react-i18next";
import GenericBtn from "../Common/GenericBtn/GenericBtn";
function limit(val: any, max: any) {
  if (val.length === 1 && val[0] > max[0]) {
    val = "0" + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = "01";

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function cardExpiry(val: any) {
  let month = limit(val.substring(0, 2), "12");
  let year = val.substring(2, 6);

  return month + (year.length || month.length > 1 ? "/" + year : "");
}
interface IProps {
  handleSubmit: (form: IWorldPay) => void;
  cardType: string;
}
const AddCard: React.FC<IProps> = ({ handleSubmit, cardType }) => {
  const { t } = useTranslation();
  return (
    <Form
      onSubmit={(values: IWorldPay) => {
        values.cardType = cardType;
        handleSubmit(values);
      }}
      render={({
        handleSubmit,
        invalid,
        submitting,
        submitError,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Field
            label={t("nameOnCard")}
            name="name"
            component={TextInput}
            placeholder={t("name")}
          />
          <Field
            label={t("cardNumber")}
            name="cardNumber"
            component={NumberFormatCustom}
            textFormat="#### #### #### ####"
          />
          <Field
            label={t("expiration")}
            name="date"
            component={NumberFormatCustom}
            textFormat={cardExpiry}
            placeholder="MM/YYYY"
          />
          <Field
            label="CVC"
            name="cvc"
            component={NumberFormatCustom}
            textFormat="###"
            placeholder="CVC"
          />
          <Grid container justifyContent="center" className="modalBtn">
            <GenericBtn
              type="submit"
              variant="contained"
              color="primary"
              label={t("submit")}
            />
          </Grid>
        </form>
      )}
    />
  );
};

export default AddCard;
