import { Backdrop } from "@material-ui/core";
import { LoadingStyles } from "./loadingStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loading = () => {
  const classes = LoadingStyles();
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
