import { useState, useEffect } from "react";
import agent from "../../../Services/agent";
import { IUserEdit, UserDetailToUSerEdit } from "../../../Models/user";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { FORM_ERROR } from "final-form";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
import ErrorMessage from "../../../utils/ErrorMessage";
import { combineValidators, isRequired } from "revalidate";
import GenericBtn from "../../Common/GenericBtn/GenericBtn";
import TextInput from "../../../utils/TextInput";

const ProfileAccount = () => {
  const { t } = useTranslation();

  const validate = combineValidators({
    firstName: isRequired({ message: t("required") }),
    lastName: isRequired({ message: t("required") }),
  });
  const [user, setUser] = useState<IUserEdit>({
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    phoneNumber: "",
    userName: "",
    rolesId: [],
  });
  const getUser = async () => {
    await agent.User.userDetail().then((res) =>
      setUser(UserDetailToUSerEdit(res))
    );
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h6">Information</Typography>
        <Typography variant="body1">
          Personal details of your accounts are shown below.
        </Typography>
      </Grid>
      <Form
        initialValues={user}
        validate={validate}
        onSubmit={(values: IUserEdit) => {
          agent.User.modify(values, user.id)
            .then(() => {
              toast.success(t("submittedSuccessfully"));
            })
            .catch((error) => {
              return {
                [FORM_ERROR]: error,
              };
            });
        }}
        render={({
          handleSubmit,
          invalid,
          submitting,
          submitError,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            {submitError && !dirtySinceLastSubmit && (
              <ErrorMessage text="Error(s)" error={submitError} />
            )}
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                <Field
                  label={t("firstName")}
                  name="firstName"
                  component={TextInput}
                  placeholder={t("firstName")}
                />
                <Field
                  label={t("lastName")}
                  name="lastName"
                  component={TextInput}
                  placeholder={t("lastName")}
                />
                <Field
                  label={t("phoneNumber")}
                  name="phoneNumber"
                  component={TextInput}
                  placeholder={t("phoneNumber")}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} container justifyContent="center">
                <GenericBtn
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={(invalid && !dirtySinceLastSubmit) || submitting}
                >
                  {t("submit")}
                </GenericBtn>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Grid>
  );
};

export default ProfileAccount;
