import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const ProductList2Styles = makeStyles((theme: Theme) =>
  createStyles({
    categoryTitle: {
      marginBottom: "10px !important",
      display: "inline-block",
      padding: "5px 10px",
      color: theme.palette.text.primary,
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
    description: {
      color: theme.palette.text.hint,
      padding: 10,
    },
    foods: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      marginTop: "0.5rem !important",
    },
  })
);
