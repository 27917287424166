import React from "react";
import ProfileMenu from "../components/Profile/ProfileMenu/ProfileMenu";
import ProfileAccount from "../components/Profile/ProfileAcount/ProfileAccount";
import ProfilePassword from "../components/Profile/ProfilePassword/ProfilePassword";
import ProfileOrders from "../components/Profile/ProfileOrders/ProfileOrders";
import { Switch, Route, Redirect } from "react-router-dom";
import ProfileInvoice from "../components/Profile/ProfileInvoice/ProfileInvoice";
import AddressList from "../components/Address/AddressList";
import "../components/Profile/profile.scss";
import { Paper, Grid } from "@material-ui/core";

const Profile = () => {
  return (
    <Grid container className="profile">
      <Grid xs={12} sm={3} lg={2} className="profileMenu">
        <ProfileMenu />
      </Grid>
      <Grid xs={12} sm={9} lg={10} className="profileMain">
        <Paper
          elevation={0}
          square
          style={{
            minHeight: "100%",
          }}
        >
          <Switch>
            <Route path="/profile/password" component={ProfilePassword} />
            <Route
              path="/profile/order-invoice/:id"
              component={ProfileInvoice}
            />
            <Route path="/profile/orders" component={ProfileOrders} />
            <Route path="/profile/addresses" component={AddressList} />
            <Route exact path="/profile" component={ProfileAccount} />
            <Redirect to="/404" />
          </Switch>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Profile;
