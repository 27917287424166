import { Badge, Grid, Hidden, IconButton, Typography } from "@material-ui/core";
import { MenuRounded, ShoppingCart } from "@material-ui/icons";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneEnabledOutlinedIcon from "@material-ui/icons/PhoneEnabledOutlined";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { history } from "../../../../src";
import { getLocalStorage } from "../../../Services/common";
import { selectAllowDelivery } from "../../../Stores/alowDelivery";
import { selectDelivery } from "../../../Stores/deliverySlice";
import {
  selectInformation,
  selectMoney,
} from "../../../Stores/informationSlice";
import { selectCart } from "../../../Stores/redisCartSlice";
import GenericBtn from "../../Common/GenericBtn/GenericBtn";
import DeliveryCollectionModal from "../../DeliveryCollectionModal/DeliveryCollectionModal";
import Logo from "../../Logo/Logo";
import { NavbarStyles } from "./NavbarStyles";
import DrawerMenu from "./components/DrawerMenu/DrawerMenu";
import Menu from "./components/Menu/Menu";

const singleStore = [null, "true"].includes(getLocalStorage("singleStore"))
  ? []
  : [
      {
        name: "Change Store",
        to: "/",
      },
    ];

const mobileMenuItems = [
  {
    name: "specialOffers",
    to: "/store/offers",
  },
  {
    name: "deliveries",
    to: "/store/delivery-information",
  },
  {
    name: "allergyAdvice",
    to: "/store/allergy-advice",
  },
  {
    name: "contactUs",
    to: "/store/contact",
  },
  {
    name: "parking",
    to: "/store/parking",
  },
  {
    name: "aboutUs",
    to: "/store/about",
  },
  {
    name: "Terms & Conditions",
    to: "/store/conditions",
  },
  {
    name: "privacy",
    to: "/store/privacy",
  },
  ...singleStore,
];

const menuItems = [
  {
    name: "specialOffers",
    to: "/store/offers",
  },
  {
    name: "deliveries",
    to: "/store/delivery-information",
  },
  {
    name: "contactUs",
    to: "/store/contact",
  },
  {
    name: "parking",
    to: "/store/parking",
  },
  ...singleStore,
];

type TProps = {
  telephone: string;
  address: string;
  openOrder: boolean;
  temporaryShopClose: boolean;
};

const Navbar: React.FC<TProps> = ({
  address,
  telephone,
  openOrder,
  temporaryShopClose,
}) => {
  const { t } = useTranslation();
  const classes = NavbarStyles();
  const information = useSelector(selectInformation);
  const allowDelivery = useSelector(selectAllowDelivery);
  const location = useLocation();
  const deliverySelected = new URLSearchParams(location.search).get("delivery");
  const [openModal, setOpenModal] = useState<boolean>(
    (information.openOrder || information.preOrder) &&
      (information.minimumDeliveryTime > 0 ||
        information.minimumCollectionTime > 0) &&
      location.pathname.startsWith("/store/ordering/departments")
  );
  const delivery = useSelector(selectDelivery);
  const [open, setOpen] = useState<boolean>(false);
  const cart = useSelector(selectCart);
  const money = useSelector(selectMoney);
  const badge = cart.userCart.carts
    .map((a) => a.quantity)
    ?.reduce((a, b) => a + b, 0);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  const RemoveEmptyContentLink = (
    menu: {
      name: string;
      to: string;
    }[]
  ) => {
    return menu.flatMap((item) => {
      if (
        (item.name === "specialOffers" && !information.specialOffersText) ||
        (item.name === "deliveries" && !information.deliveryInformation) ||
        (item.name === "allergyAdvice" && !information.returns) ||
        (item.name === "contactUs" &&
          !information.contactUs &&
          !information.openingTime &&
          !(information.longitude && information.latitude)) ||
        (item.name === "parking" &&
          !information.parking &&
          !(information.parkingLatitude && information.parkingLongitude)) ||
        (item.name === "aboutUs" && !information.aboutUs) ||
        (item.name === "Terms & Conditions" && !information.termsCondition) ||
        (item.name === "privacy" && !information.privacyPolicy)
      ) {
        return [];
      } else {
        return item;
      }
    });
  };

  const menuLinks = RemoveEmptyContentLink(menuItems);
  const mobileLinks = RemoveEmptyContentLink(mobileMenuItems);

  return (
    <Grid
      container
      justifyContent="center"
      className={classes.navbar}
      id="top-anchor"
    >
      <Hidden xsDown>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          sm={12}
          className={classes.heading}
        >
          <Grid item lg={8} className={classes.address}>
            {address && (
              <>
                <LocationOnOutlinedIcon
                  className={classes.icon}
                  fontSize="small"
                />
                &nbsp;
                <div
                  dangerouslySetInnerHTML={{
                    __html: address,
                  }}
                />
              </>
            )}
            {telephone && (
              <Grid item className={classes.tel}>
                <PhoneEnabledOutlinedIcon
                  className={classes.icon}
                  fontSize="small"
                />
                &nbsp;
                <div
                  dangerouslySetInnerHTML={{
                    __html: telephone,
                  }}
                />
                &nbsp;
              </Grid>
            )}
          </Grid>

          <Grid
            item
            lg={4}
            container
            justifyContent="flex-end"
            className={classes.orderStatus}
          >
            <GenericBtn
              disabled={!allowDelivery}
              variant="contained"
              color="default"
              size="small"
              onClick={() => setOpenModal(true)}
              className="deliveryCollection"
              style={{ margin: 0 }}
            >
              {delivery.delivery ? t("delivery") : t("collection")}&nbsp;
              <CachedRoundedIcon fontSize="small" />
            </GenericBtn>
          </Grid>
        </Grid>
        <Grid item container sm={12} className={classes.menu}>
          <Grid
            item
            lg={2}
            container
            justifyContent="center"
            className={classes.storeLogo}
          >
            <Logo />
          </Grid>
          <Grid item lg={8}>
            <Menu {...{ menuLinks }} />
          </Grid>
          <Grid
            item
            lg={2}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <GenericBtn
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => history.push("/store/ordering/departments")}
                label={
                  !information.openOrder && information.preOrder
                    ? t("preOrdering")
                    : !information.openOrder && !information.preOrder
                    ? t("takeawayMenu")
                    : t("orderNow")
                }
                style={{ margin: "4px 0 0 0" }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="caption"
                color={
                  openOrder && temporaryShopClose ? "secondary" : "primary"
                }
              >
                Ordering is{" "}
                {openOrder && !temporaryShopClose ? t("open") : t("closed")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          xs={12}
        >
          <Grid item container xs={6} alignItems="center">
            <IconButton
              className={classes.menuBtn}
              size="small"
              onClick={toggleDrawer(true)}
            >
              <MenuRounded fontSize="large" />
            </IconButton>
            <GenericBtn
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => history.push("/store/ordering/departments")}
              label={
                !information.openOrder && information.preOrder
                  ? t("preOrdering")
                  : !information.openOrder && !information.preOrder
                  ? t("takeawayMenu")
                  : t("orderNow")
              }
              style={{ margin: 0 }}
            />
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            <GenericBtn
              disabled={!allowDelivery}
              variant="text"
              color="default"
              size="small"
              onClick={() => setOpenModal(true)}
              className="deliveryCollection"
              style={{ margin: 0 }}
            >
              {delivery.delivery ? t("delivery") : t("collection")}&nbsp;
              <CachedRoundedIcon fontSize="small" />
            </GenericBtn>
            <Badge
              badgeContent={badge}
              color="secondary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <GenericBtn
                size="small"
                variant="contained"
                onClick={() =>
                  document.getElementById("cart")?.scrollIntoView()
                }
                className={classes.cartBtn}
              >
                <ShoppingCart fontSize="small" color="primary" />
                &nbsp;
                {money(cart.userCart.total)}
              </GenericBtn>
            </Badge>
          </Grid>
        </Grid>
        <DrawerMenu
          {...{
            tel: telephone,
            menu: mobileLinks,
            open,
            toggleDrawer,
            address,
          }}
        />
      </Hidden>

      {allowDelivery && openModal && (
        <DeliveryCollectionModal
          preOrder={information.preOrder}
          open={openModal}
          handleClose={() => setOpenModal(false)}
          deliverySelected={deliverySelected?.toLocaleLowerCase() === "true"}
        />
      )}
    </Grid>
  );
};

export default Navbar;
