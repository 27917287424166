import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const MainProduct2Styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem",
      borderRadius: 5,
      color: theme.palette.text.primary,
      "@media only screen and (max-width: 959px)": {
        padding: "0.3rem 0.5rem",
      },

      "& h6": {
        display: "block",
        color: theme.palette.text.primary,
        fontSize: "1rem",
      },

      "& h6.sizeTitle": {
        "@media only screen and (max-width: 959px)": {
          marginRight: "auto",
          marginLeft: "auto",
        },
      },

      "& .crust": {
        /* The container */
        "& label": {
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          position: "relative",
          paddingLeft: 30,
          margin: "0 2rem 7px 0",
          cursor: "pointer",
          fontSize: "1.4rem",
          WebkitUserSelect: "none",
          MozUserSelect: "none",
          MsUserSelect: "none",
          userSelect: "none",

          "@media only screen and (max-width: 959px)": {
            margin: "0 auto 10px 0",
            cursor: "pointer",
            fontSize: "1.3rem",
          },

          /* Style the indicator (dot/circle) */
          "& .checkmark::after": {
            top: 4,
            left: 4,
            width: 8,
            height: 8,
            borderRadius: "50%",
            background: theme.palette.grey[500],
          },
        },

        /* Hide the browser's default radio button */
        "& label input": {
          position: "absolute",
          opacity: 0,
          cursor: "pointer",
        },

        /* Create a custom radio button */
        "& .checkmark": {
          position: "absolute",
          top: 4,
          left: 0,
          height: 16,
          width: 16,
          border: "solid 1px #707070",
          borderRadius: "50%",
        },

        /* On mouse-over, add a grey background color */
        "& label:hover input ~ .checkmark": {
          backgroundColor: "#55555580",
        },

        /* When the radio button is checked, add a background */
        "& label input:checked ~ .checkmark": {
          border: "1px solid " + theme.palette.grey[300],
        },

        /* Create the indicator (the dot/circle - hidden when not checked) */
        "& .checkmark:after": {
          content: '""',
          position: "absolute",
          display: "none",
        },

        /* Show the indicator (dot/circle) when checked */
        "& label input:checked ~ .checkmark:after": {
          display: "block",
        },
      },

      "& .extras": {
        display: "block",
        position: "relative",
        margin: "0 8px 8px 0",
        cursor: "pointer",
        fontSize: "1.4rem",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",

        /* Hide the browser's default checkbox */
        "& input": {
          position: "absolute",
          opacity: 0,
          cursor: "pointer",
          height: 0,
          width: 0,
        },

        /* Create a custom checkbox */
        "& .checkmark": {
          backgroundColor: "#fff",
          border: "solid 1px #707070",
          borderRadius: 5,
          padding: "0.2rem 1rem",
        },

        /* On mouse-over, add a grey background color */
        "&:hover input ~ .checkmark": {
          backgroundColor: "#ccc",
        },

        /* When the checkbox is checked, add a red background */
        "& input:checked ~ .checkmark": {
          backgroundColor: "#fc7d36",
          color: "#fff",
        },

        /* Show the checkmark when checked */
        "& input:checked ~ .checkmark:after": {
          display: "block",
        },

        /* Style the checkmark/indicator */
        "& .checkmark:after": {
          left: 9,
          top: 5,
          width: 5,
          height: 10,
          border: "solid white",
          borderWidth: "0 3px 3px 0",
          WebkitTransform: "rotate(45deg)",
          MsTransform: "rotate(45deg)",
          transform: "rotate(45deg)",
        },
      },
    },

    foodName: {
      "& h2": {
        color: theme.palette.text.primary,
        textAlign: "center",

        "& span": {
          paddingLeft: "1rem",
          fontSize: "80%",
          "@media (max-width: 959px)": {
            paddingLeft: "unSet",
          },
        },

        "@media (max-width: 959px)": {
          fontSize: "1.5rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      },
    },

    description: {
      fontSize: "1.2rem",
      padding: "0 0 1rem 1rem",
      color: theme.palette.text.primary,
      textAlign: "center",

      "& table": {
        width: "100%",
      },
      "& p": {
        textAlign: "center",
      },
    },

    optionsRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      "@media(max-width: 959px)": {
        flexDirection: "column",
        borderTop: "1px solid #eee",
        paddingTop: "8px",
      },
    },

    optionRows: {
      "& .attrRow": {
        display: "flex",
        marginTop: "1rem",
        "@media(max-width: 959px)": {
          flexDirection: "column",
          alignItems: "center",
          marginTop: 0,
        },
      },
    },

    optionImage: {
      width: "70%",
      [theme.breakpoints.up("sm")]: {
        width: "80%",
        height: "80%",
      },
      [theme.breakpoints.up("md")]: {
        width: "40%",
        height: "40%",
      },
      [theme.breakpoints.up("lg")]: {
        width: "30%",
        height: "30%",
      },
      [theme.breakpoints.up("xl")]: {
        width: "20%",
        height: "20%",
      },
    },
  })
);
