import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const PaymentStyles = makeStyles((theme: Theme) =>
  createStyles({
    Payment2: {
      padding: "1rem",
      "& .back": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        "& button": {
          margin: 10,
        },
      },

      "& .productTable": {
        "& th": {
          color: "#fff",
          fontSize: "1.2rem",
          backgroundColor: theme.palette.primary.dark,
        },
        "& td": {
          borderBottom: "1px solid #ccc",

          verticalAlign: "top",
          "& ul": {
            fontWeight: "normal",
          },
        },
      },

      "& .extraPrice": {
        listStyle: "none",
        padding: 0,
      },

      "& .total": {
        padding: "1rem",

        "& h4": {
          textAlign: "center",
          color: theme.palette.primary.main,
        },
        "& .button": {
          padding: "0.8rem",
          marginBottom: "0.7rem",
        },
      },
      "& .discount": {
        "& td": {
          color: "red",
          fontWeight: "600 !important",
          fontSize: "1rem",
          [theme.breakpoints.down("xs")]: {
            fontSize: "0.9rem !important",
          },
        },
      },
    },

    total: {
      "& td": {
        fontWeight: "600 !important",
        fontSize: "1rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "0.9rem !important",
        },
      },
    },

    productTableXS: {
      marginBottom: 20,

      "& th:first-child": {
        paddingRight: "0 !important",
      },
      "& th": {
        fontSize: "0.7rem",

        padding: "5px !important",
        color: "#fff",
        backgroundColor: "#555",
      },
      "& td:first-child": {
        fontWeight: 500,
      },
      "& td": {
        fontSize: "0.8rem",
        padding: "5px !important",

        verticalAlign: "top",
        "& ul": {
          fontSize: "0.7rem",
          fontWeight: "normal",
        },
      },
      "& .extraRow": {
        "& td": {
          paddingTop: "0 !important",
        },
        "& .MuiTableCell-root": {
          borderTop: "none",
        },
      },
      "& .extrasPrice": {
        "& li": {
          fontWeight: 500,
        },
      },

      "& .MuiTableCell-root": {
        borderBottom: "none",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    deliverNote: {
      "& label": {
        zIndex: 0,
      },

      "& input": {
        display: "block",
        height: 180,
        width: "100%",
        border: "solid 1px #707070",
        borderRadius: 5,
        padding: "1rem",
        margin: "0 auto",
        overflowY: "hidden",
      },
    },
    cards: {
      "& button": {
        margin: 5,
        "& img": {
          height: "100%",
          [theme.breakpoints.up("xs")]: {
            width: 100,
          },
          [theme.breakpoints.up("xl")]: {
            width: 120,
          },
        },
      },
    },
    timePicker: {
      display: "flex",

      [theme.breakpoints.down("xs")]: {
        display: "flex",
        alignItems: "center",
      },
      [theme.breakpoints.down("lg")]: {
        display: "flex",
        alignItems: "center",
        marginTop: 10,
      },
      "& h6": {
        margin: "0 10px 0 0 !important",
      },
    },
    voucherCodeGrid: {
      "& .voucherCodeTextField": {
        "& .MuiInputBase-root": {
          margin: 10,
        },

        "& input": {
          padding: "8.5px 14px !important",
        },
      },

      "& .MuiButtonBase-root": {
        padding: "5px 22px !important",
      },
    },
    asap: {
      marginLeft: 20,
    },
  })
);
