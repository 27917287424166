import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import SignIn from "../components/Sign/SignIn";
import SignUp from "../components/Sign/SignUp";

import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";

import { history } from "..";
import Loading from "../components/Loading/Loading";
import { RegisterType } from "../Models/order";
import agent from "../Services/agent";
import { selectCart } from "../Stores/redisCartSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "@media only screen and (min-width: 600px)": {
        height: 800,
      },

      "@media only screen and (min-width: 960px)": {
        height: 1120,
      },

      "@media only screen and (min-width: 1280px)": {
        height: 580,
      },

      "@media only screen and (min-width: 1920px)": {
        height: 757,
      },
    },
  })
);

interface IProps {
  match: any;
}

const Sign: React.FC<IProps> = ({ match }) => {
  const classes = useStyles();
  const backUrl = match.params.backUrl as string;
  const [loading, setLoading] = useState(false);
  const cartList = useSelector(selectCart);
  const [step, setStep] = useState<"login" | "register">("login");

  const SubmitOrder = async () => {
    setLoading(true);

    await agent.Order.create({
      cartId: cartList.userCart.id,
      orderType: RegisterType.Web,
    })
      .then((res) => {
        history.push(`/store/payment/${res}`);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("something went wrong try again");
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (backUrl === "store/order") SubmitOrder();
    else if (backUrl === "store/address") history.push("/store/order/address");
    else if (backUrl) history.push(`/store/order/${backUrl}`);
    else
      window.location.href =
        localStorage.getItem("pushPath") !== null
          ? (localStorage.getItem("pushPath") as string)
          : "/store";
  };

  if (loading) return <Loading />;

  return (
    <Grid container justifyContent="center" className={classes.root}>
      {step === "login" ? (
        <SignIn handleSubmit={handleSubmit} {...{ step, setStep }} />
      ) : (
        <SignUp handleSubmit={handleSubmit} {...{ step, setStep }} />
      )}
    </Grid>
  );
};

export default Sign;
