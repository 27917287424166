import { PayloadAction } from "@reduxjs/toolkit";
import { GenericState } from "../Models/state";
import createGenericSlice from "./GenericSlice";

export interface ICommon {
  categoryId?: string;
  productOptionsModal: {
    open: boolean;
    mainProductId: string;
    productId: string;
    duplicateNumber: number;
  };
}

export const slice = createGenericSlice({
  name: "common",
  initialState: {
    status: "loading",
    data: {
      categoryId: getCategoryId(),
      productOptionsModal: {
        open: false,
        mainProductId: "",
        productId: "",
        duplicateNumber: 1,
      },
    },
  } as GenericState<ICommon>,
  reducers: {
    setCategoryId: (state, action: PayloadAction<string>) => {
      window.localStorage.setItem("common", JSON.stringify(action.payload));
      return { ...state, categoryId: action.payload };
    },
    removeCategoryId: (state) => {
      if (state.data) {
        state.data.categoryId = undefined;
        window.localStorage.removeItem("common");
      }
    },
    setProductOptionModal: (
      state,
      action: PayloadAction<ICommon["productOptionsModal"]>
    ) => {
      state.data = {
        ...state,
        productOptionsModal: action.payload,
      };
    },
  },
});
export const selectCategoryId = (state: { common: { data: ICommon } }) =>
  state.common.data.categoryId;

export const selectProductOptionModal = (state: {
  common: { data: ICommon };
}) => state.common.data.productOptionsModal;

export const { setCategoryId, removeCategoryId, setProductOptionModal } =
  slice.actions;
export default slice.reducer;

function getCategoryId() {
  const val = window.localStorage.getItem("common");
  let common = {
    categoryId: undefined,
  };
  if (val) {
    common = JSON.parse(val);
  }
  return common.categoryId;
}
