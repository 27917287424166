import { Hidden } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { history } from "../../../../..";
import { selectUser } from "../../../../../Stores/userSlice";
import CHoverMenu from "../../../../HoverMenu/CHoverMenu";
import { MenuStyles, StyledLink } from "./MenuStyles";

interface IProps {
  menuLinks: {
    name: string;
    to: string;
  }[];
}

const Menu: React.FC<IProps> = ({ menuLinks }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const isAdmin = user && user.roles.indexOf("Administrator") !== -1;
  const isUser = user && user.token;
  const isGuest = user.guest;
  const isTableOrder = user.tableOrder;
  const classes = MenuStyles();

  const hoverMenuItems = [
    isAdmin
      ? {
          href: "/panel",
          text: t("adminDashboard"),
        }
      : {},
    {
      href: "/store/profile",
      text: t("profile"),
    },
    {
      href: "/store/",
      text: t("signOut"),
      onClick: () => {
        localStorage.removeItem("account");
        window.location.replace("/store/");
      },
    },
  ].filter((item) => Object.keys(item).length !== 0);

  return (
    <div className={classes.menu2}>
      <Hidden xsDown>
        <StyledLink href="/store">{t("home")}</StyledLink>
        {menuLinks.map((item) => (
          <StyledLink key={item.name} href={item.to}>
            {t(item.name)}
          </StyledLink>
        ))}

        {isUser && !isGuest && !isTableOrder ? (
          <CHoverMenu {...{ hoverMenuItems, user }} />
        ) : (
          <StyledLink
            href="/store/login"
            underline="none"
            onClick={() => history.push("/store/login")}
          >
            {t("signIn")}
          </StyledLink>
        )}
      </Hidden>
    </div>
  );
};

export default Menu;
