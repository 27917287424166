import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Grid, Typography } from "@material-ui/core";
import FreebiesItemWithRedisCart from "./FreebiesItem/FreebiesItemWithRedisCart";
import ScrollTop from "../../Common/ScrollTop/ScrollTop";
import { FreebiesProductsStyles } from "./FreebiesProductsStyles";
import React from "react";
import agent from "../../../Services/agent";
import { IFreebies } from "../../../Models/freebies";
interface IProps {
  match: any;
}
const FreebiesProducts: React.FC<IProps> = ({ match }) => {
  const { t } = useTranslation();
  const classes = FreebiesProductsStyles();
  const { pathname } = useLocation();
  const freebieId = match.params.freebieId;
  const [freebies, setFreebies] = useState<IFreebies>();
  // =======================useSelector=======================
  const getFreebies = (freebieId: string) => {
    agent.Freebies.getById(freebieId).then((res) => {
      setFreebies(res);
    });
  };

  // =======================useEffect=========================
  useEffect(() => {
    window.scrollTo(0, 0);
    getFreebies(freebieId);
  }, [pathname]);

  // ==========================tsx============================
  return (
    <div className={classes.freebiesRoot}>
      <Container>
        <Typography
          className={classes.categoryTitle}
          variant="h4"
          component="h2"
        >
          {t("freebiesProducts")}
        </Typography>
        <Grid item xs={12}>
          <div className={classes.description}>
            {t("youCanPick")}&nbsp;
            {freebies ? freebies.quantity : 0}&nbsp;
            {t("freebies")}
          </div>
        </Grid>
      </Container>

      <Grid item xs={12} className={classes.foods}>
        {freebies &&
          freebies.productFreebies
            .map((el) => el.product)
            .sort((a, b) => {
              const nameA = a.categoryName.toLowerCase();
              const nameB = b.categoryName.toLowerCase();
              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            })
            .map((item) => (
              <FreebiesItemWithRedisCart
                key={item.id}
                product={item}
                showPrice={freebies.discountType === "Freebie"}
                quantity={freebies.quantity}
              />
            ))}
      </Grid>
      <ScrollTop />
    </div>
  );
};

export default FreebiesProducts;
