import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const NavbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    navbar: {
      width: "100vw",
      position: "relative",
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        padding: "5px 0",
      },
    },
    heading: {
      height: 35,
      padding: "0 2px",
    },
    menu: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.customPalette.menuBg,

      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        "& button": {
          margin: "0 0 1rem 2rem",
        },
      },
    },
    storeName: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",

      "& a": {
        width: "80%",
        color: theme.palette.title.main,
        "&:hover": {
          opacity: 0.9,
        },
        "& h2": {
          margin: 0,
          lineHeight: 1.3,
          textAlign: "center",
        },
        "& h3": {
          margin: 0,
          textAlign: "center",
        },
      },
    },
    storeLogo: {
      "& img": {
        objectFit: "contain",
        width: 90,
        margin: "5px 0 0",
        display: "block",
        [theme.breakpoints.up("sm")]: {
          height: 120,
        },
        [theme.breakpoints.up("lg")]: {
          height: 120,
          top: 5,
        },
      },

      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        "& img": {
          width: 270,
          height: 150,
        },
      },
    },
    ratingImage: {
      width: "120px",

      "@media(min-width:960px)": {
        position: "absolute",
        bottom: "40px",
        left: "0",
        width: "150px",
      },
    },

    menuBtn: {
      color: theme.customPalette.fontHeaderColor,
    },

    orderNowBtn: {
      display: "flex",
      justifyContent: "center",
      zIndex: 3,
      borderRadius: 5,

      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.down("sm")]: {},

      "& a": {
        padding: 7,
        backgroundColor: "#284919",
        color: "#ffffff !important",

        borderRadius: 5,
        "& svg": {
          marginLeft: "0.5rem",
        },
      },
    },
    orderStatus: {
      paddingRight: 20,
      "& span:only-of-type": {
        marginLeft: 5,
        padding: "2px 8px",
        backgroundColor: "#fff",
        borderRadius: 5,
      },
      "& .open": {
        color: "green",
      },
      "& .close": {
        color: "red",
      },
    },
    cartBtn: {
      width: 100,
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#fff",
      },
      "& .MuiButton-label": {
        display: "flex",
        justifyContent: "space-around",
      },
    },
    address: {
      display: "flex",
      color: theme.palette.primary.main,
      padding: "3px 5px",
      fontSize: "0.9rem",
    },
    tel: {
      display: "flex",
      padding: "3px 5px",
      color: theme.customPalette.fontHeaderColor,

      [theme.breakpoints.down("xs")]: {
        display: "none",
      },

      "& p": {
        margin: 0,
        color: theme.customPalette.fontHeaderColor,
      },
    },
    icon: {
      color: theme.customPalette.fontHeaderColor,
    },
  })
);
