import { useSelector } from "react-redux";
import CheckoutPage from "../Pages/CheckoutPage";
import OrderingWithRedisCart from "../Pages/OrderingWithRedisCart";
import PaymentPage from "../Pages/PaymentPage";
import Profile from "../Pages/Profile";
import Sign from "../Pages/Sign";
import { selectInformation } from "../Stores/informationSlice";
import { selectUser } from "../Stores/userSlice";
import _404Page from "../components/404Page/_404Page";
import CheckBooking from "../components/BookingTable/CheckBooking";
import EditBooking from "../components/BookingTable/EditBooking/EditBooking";
import SubmitBooking from "../components/BookingTable/SubmitBooking/SubmitBooking";
import TableOrdering from "../components/Client/TableOrdering/TableOrdering";
import ContactUs from "../components/ContactUs/ContactUs";
import LandingPage from "../components/Design2/LandingPage/LandingPage";
import RestaurantLandingPage from "../components/Design2/RestaurantLandingPage/RestaurantLandingPage";
import InformationPage from "../components/InformationPage/InformationPage";
import Offers from "../components/Offers/Offers";
import OpeningTimes from "../components/OpeningTimes/OpeningTimes";
import ParkingInfo from "../components/ParkingInfo/ParkingInfo";
import GuestRegister from "../components/Sign/GuestRegister/GuestRegister";
import ResetPassword from "../components/Sign/ResetPassword/ResetPassword";

export const useRoutes = () => {
  const information = useSelector(selectInformation);
  const user = useSelector(selectUser);

  const routes = [
    {
      path: "/store/profile",
      component: Profile,
      isLoggedIn: !!user.token,
      isInRole: true,
    },
    {
      path: "/store/booking/:time/:selectedDate/:person",
      component: SubmitBooking,
    },
    { path: "/store/edit", component: EditBooking },
    { path: "/store/account/resetPassword", component: ResetPassword },
    { path: "/store/payment/:orderId", component: PaymentPage },
    { path: "/store/contact", component: ContactUs },
    { path: "/store/parking", component: ParkingInfo },
    {
      path: "/store/about",
      component: () => <InformationPage htmlValue={information.aboutUs} />,
    },
    {
      path: "/store/privacy",
      component: () => (
        <InformationPage htmlValue={information.privacyPolicy} />
      ),
    },
    { path: "/store/opening-time", component: OpeningTimes },
    { path: "/store/offers", component: Offers },
    {
      path: "/store/allergy-advice",
      component: () => <InformationPage htmlValue={information.returns} />,
    },
    { path: "/store/address", component: CheckoutPage },
    { path: "/store/guest-ordering", component: GuestRegister },
    {
      path: "/store/conditions",
      component: () => (
        <InformationPage htmlValue={information.termsCondition} />
      ),
    },
    {
      path: "/store/delivery-information",
      component: () => (
        <InformationPage htmlValue={information.deliveryInformation} />
      ),
    },
    { path: "/store/login/:backUrl?", component: Sign },
    { path: "/store/ordering", component: OrderingWithRedisCart },
    { path: "/store/table-reserve", component: CheckBooking },
    { path: "/404", component: _404Page },
    { path: "/store/tableOrdering/:tableNumber?", component: TableOrdering },
    information.storeType === "Restaurant"
      ? { path: "/store", exact: true, component: RestaurantLandingPage }
      : information.storeType === "Takeaway"
      ? {
          path: "/store",
          exact: true,
          component: LandingPage,
        }
      : {},
  ];

  return routes;
};
