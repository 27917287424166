import React from "react";
import Slick from "react-slick";
import { Grid } from "@material-ui/core";
import { SliderStyles } from "./SliderStyles";
import { ITextSlider } from "../../Models/textSlider";

const settings = {
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  row: 1,
  arrows: false,
  autoplay: true,
  pauseOnHover: true,
  rtl: false,
  className: "carouselHeight",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
interface IProps {
  sliders: ITextSlider[];
}
const CustomSlider: React.FC<IProps> = ({ sliders }) => {
  const classes = SliderStyles();

  return sliders.length > 0 ? (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Slick {...settings}>
          {sliders.map((item) => (
            <div
              dangerouslySetInnerHTML={{ __html: item.text }}
              key={item.id}
            />
          ))}
        </Slick>
      </Grid>
    </Grid>
  ) : null;
};

export default CustomSlider;
