import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const ProfileOrderStyles = makeStyles((theme: Theme) =>
  createStyles({
    ProfileOrder: {
      "& .profileTitle": {
        textAlign: "center",
        color: "#0c2936",
      },
    },
  })
);
