import React from "react";
import { FieldRenderProps } from "react-final-form";
import { TextField } from "@material-ui/core";

interface IProps extends FieldRenderProps<number, HTMLElement> {
  placeholder?: string;
  label: string;
  type: string;
  required?: true;
  fullWidth?: boolean;
  min?: number;
}

const NumberInput: React.FC<IProps> = ({
  input,
  label,
  meta: { touched, error },
  required,
  fullWidth = true,
  placeholder = "",
  min,
}) => {
  return (
    <TextField
      id="outlined-basic"
      label={label}
      variant="outlined"
      type="number"
      {...input}
      inputProps={{
        step: "0.01",
        min: min ?? 0,
      }}
      onChange={(event) => {
        const val = event.target.value as string;
        input.onChange(parseFloat(val));
      }}
      helperText={error}
      error={touched && error}
      size="small"
      fullWidth={fullWidth}
      required={required}
      margin="normal"
    />
  );
};

export default NumberInput;
