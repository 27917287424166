import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  useScrollTrigger,
  Zoom,
  Fab,
} from "@material-ui/core";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollTop: {
      position: "fixed",
      bottom: 20,
      zIndex: 99,

      [theme.breakpoints.down("xs")]: {
        right: 10,
      },
      [theme.breakpoints.down("md")]: {
        right: 20,
      },
      [theme.breakpoints.up("lg")]: {
        right: 100,
      },
      [theme.breakpoints.up("xl")]: {
        right: 100,
      },
    },
  })
);

export default function ScrollTop() {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.scrollTop}
      >
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpRoundedIcon />
        </Fab>
      </div>
    </Zoom>
  );
}
