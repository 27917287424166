import { ClickAwayListener, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { isAndroid, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectInformation } from "../../../Stores/informationSlice";
import iosShare from "../../../assets/img/ios share button.svg";
import GenericBtn from "../GenericBtn/GenericBtn";
import { AddToHomeScreenStyles } from "./AddToHomeScreenStyles";

const AddToHomeScreen = () => {
  const { t } = useTranslation();
  const classes = AddToHomeScreenStyles();
  const [open, setOpen] = React.useState(true);
  const isShowed = localStorage.getItem("pwaTrigger");
  const information = useSelector(selectInformation);

  if (!isShowed) {
    let deferredPrompt: any;
    const addBtn = document.querySelector(".add-button") as HTMLElement;
    const closeBtn = document.querySelector(".close") as HTMLElement;
    const installContainer = document.querySelector(
      ".installContainer"
    ) as HTMLElement;
    if (installContainer) {
      installContainer.style.display = "none";

      window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        // Update UI to notify the user they can add to home screen
        installContainer.style.display = "block";

        closeBtn.addEventListener("click", (e) => {
          installContainer.style.display = "none";
        });

        addBtn.addEventListener("click", (e) => {
          // hide our user interface that shows our A2HS button
          installContainer.style.display = "none";
          // Show the prompt
          deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          deferredPrompt.userChoice.then((choiceResult: any) => {
            if (choiceResult.outcome === "accepted") {
              console.log("User accepted the A2HS prompt");
            } else {
              console.log("User dismissed the A2HS prompt");
            }
            deferredPrompt = null;
          });
        });
      });
    }
  }

  const handlePwaTrigger = () => {
    document.body.style.overflow = "unset";
    localStorage.setItem("pwaTrigger", "true");
    setOpen(false);
  };

  const handleClose = () => {
    document.body.style.overflow = "auto";
    setOpen(false);
  };

  useEffect(() => {
    if (isShowed === "false") document.body.style.overflow = "hidden";
  }, []);

  if (!isMobile) return <div />;

  return (
    <div className={classes.root}>
      {isAndroid && (isShowed === null || isShowed === "false") ? (
        <Grid className="installContainer">
          <Grid
            item
            xs={12}
            container
            direction="column"
            style={{ marginBottom: 10 }}
          >
            <Typography align="center" variant="h6">
              Add {information.name.toUpperCase() ?? "Food Ordering"} To Home
              Screen
            </Typography>
            <Typography align="center" variant="body2">
              This site can be install as an application
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="space-evenly">
            <GenericBtn
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => handlePwaTrigger()}
              style={{
                minWidth: 130,
              }}
            >
              {t("install")}
            </GenericBtn>
            <GenericBtn
              variant="outlined"
              color="default"
              size="large"
              className="ok"
              onClick={() => handleClose()}
              style={{
                minWidth: 130,
              }}
            >
              No Thanks
            </GenericBtn>
          </Grid>
        </Grid>
      ) : (
        (isShowed === null || isShowed === "false") &&
        open && (
          <div className="background">
            <ClickAwayListener onClickAway={() => handleClose()}>
              <Grid container className="installContainerIos">
                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  style={{ marginBottom: 10 }}
                >
                  <Typography align="center" variant="h6">
                    Add {information.name.toUpperCase() ?? "Food Ordering"} To
                    Home Screen
                  </Typography>
                  <Typography align="center" variant="body2">
                    This site can be install as an application
                  </Typography>
                </Grid>
                <Grid item xs={12} className="description">
                  <Typography variant="body1">
                    1.Tap <img src={iosShare} alt="button" /> button at the
                    bottom of the screen.
                  </Typography>
                  <Typography variant="body1">
                    2. Tap "Add To Home Screen".
                  </Typography>
                  <Typography variant="body1">
                    3. Tap "Add" button at the top right corner.
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="space-evenly">
                  <GenericBtn
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => handlePwaTrigger()}
                    style={{
                      minWidth: 130,
                    }}
                  >
                    {t("ok")}
                  </GenericBtn>
                  <GenericBtn
                    variant="outlined"
                    color="default"
                    size="large"
                    className="ok"
                    onClick={() => handleClose()}
                    style={{
                      minWidth: 130,
                    }}
                  >
                    No Thanks
                  </GenericBtn>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </div>
        )
      )}
    </div>
  );
};

export default AddToHomeScreen;
