import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ICategory } from "../Models/category";
import agent from "../Services/agent";
import { getLocalStorage } from "../Services/common";
import {
  removeCategoryId,
  selectProductOptionModal,
  setProductOptionModal,
} from "../Stores/commonSlice";
import { selectCart, setCart } from "../Stores/redisCartSlice";
import CategoryOpenSelect from "../components/Category/CategoryOpenSelect/CategoryOpenSelect";
import MainProduct2 from "../components/Design2/Product2/MainProduct2/MainProduct2";
import ModalContainer from "../components/Modal/ModalContainer";
import Cart from "../components/RedisCart/Cart";
import useOrderRoutes from "../routes/OrderRoutes";
import { OrderingStyles } from "./Styles/Ordering2Styles";

const OrderingWithRedisCart = (props: any) => {
  const classes = OrderingStyles();
  const { location } = props;
  // =======================useSelector=======================
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const routes = useOrderRoutes(cart);
  const { open: showOption } = useSelector(selectProductOptionModal);

  // ========================useState=========================

  const [categories, setCategories] = useState<ICategory[]>([]);

  // =========================methods=========================
  const getMenuCategories = useCallback(async () => {
    await agent.Categories.homePageCategories().then((res) => {
      setCategories(res);
    });
  }, []);

  const getCart = (id: string | null) => {
    agent.Cart.get(id).then((res) => {
      dispatch(
        setCart({
          isSuccess: res.isSuccess,
          responseText: res.responseText,
          userCart: res.userCart,
        })
      );
    });
  };

  // =======================useEffect=========================
  useEffect(() => {
    if (Boolean(getLocalStorage("cartId")))
      getCart(getLocalStorage("cartId").id);
  }, []);

  useEffect(() => {
    getMenuCategories();
    if (
      location.pathname === "/store/departments" ||
      location.pathname === "/store/ordering/departments/"
    ) {
      dispatch(removeCategoryId());
    }
  }, [dispatch, getMenuCategories]);

  // ==========================tsx============================
  return (
    <Grid container className={classes.rootContainer}>
      {/* =========================Categories========================= */}
      {categories.length > 0 ? (
        <CategoryOpenSelect categories={categories} />
      ) : null}
      {/* ==========================Products========================== */}
      <Grid
        item
        xs={12}
        sm={cart.userCart.carts.length > 0 ? 8 : 12}
        md={cart.userCart.carts.length > 0 ? 8 : 12}
        lg={cart.userCart.carts.length > 0 ? 9 : 12}
      >
        <Grid className={classes.products}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact || false}
                component={route.component}
              />
            ))}
          </Switch>
        </Grid>
      </Grid>
      {/* ============================Cart============================ */}

      {cart.userCart.carts.length > 0 ? (
        <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
          <div className={classes.sticky}>
            <Cart />
          </div>
        </Grid>
      ) : null}
      <ModalContainer
        body={<MainProduct2 />}
        open={showOption}
        handleShow={() =>
          dispatch(
            setProductOptionModal({
              open: false,
              mainProductId: "",
              productId: "",
              duplicateNumber: 1,
            })
          )
        }
        fullWidth
        maxWidth="md"
        dialog={false}
      />
    </Grid>
  );
};

export default OrderingWithRedisCart;
