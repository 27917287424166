import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const ProductCounterStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGroup: {
      "& button": {
        padding: 5,
        border: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    qty: {
      padding: "0 10px",
    },
  })
);
