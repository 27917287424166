import { Container, Grid, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { history } from "../../../..";
import { IModifyCategory } from "../../../../Models/category";
import { IMainProduct } from "../../../../Models/mainProduct";
import { IPagedCollection } from "../../../../Models/pagedCollection";
import agent from "../../../../Services/agent";
import { selectDelivery } from "../../../../Stores/deliverySlice";
import { selectInformation } from "../../../../Stores/informationSlice";
import ScrollTop from "../../../Common/ScrollTop/ScrollTop";
import ProductItemWithImage from "./ProductItem2/ProductItemWithImage";
import ProductItemWithRedisCart from "./ProductItem2/ProductItemWithRedisCart";
import { ProductList2Styles } from "./ProductList2Styles";

interface IProps {
  match: any;
}

const ProductList2: React.FC<IProps> = ({ match }) => {
  const classes = ProductList2Styles();
  const { pathname } = useLocation();

  // =========================values==========================
  let categoryId = match.params.categoryId;

  // =======================useSelector=======================
  const information = useSelector(selectInformation);
  const delivery = useSelector(selectDelivery);

  // ========================useState=========================
  const [pagedProduct, setPagedProduct] = useState<
    IPagedCollection<IMainProduct>
  >({
    limit: 0,
    offset: 0,
    size: 0,
    value: [],
  });
  const [categoryForm, setCategoryForm] = useState<IModifyCategory>({
    id: "",
    name: "",
    description: "",
    isMenu: false,
    file: undefined,
    displayOrder: 0,
    backGroundColor: "",
    foreGroundColor: "",
    fontSize: 0,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  // =========================methods=========================
  function products(delivery: boolean) {
    if (delivery)
      return pagedProduct.value
        .filter((a) => a.delivery)
        .sort((a, b) => a.displayOrder - b.displayOrder);
    else
      return pagedProduct.value
        .filter((a) => a.collection)
        .sort((a, b) => a.displayOrder - b.displayOrder);
  }

  const getProducts = useCallback(async () => {
    await agent.MainProduct.list(categoryId, 100, 0, "")
      .then((res) => setPagedProduct(res))
      .catch(
        (error) =>
          error.status === 400 &&
          (history.push("/404"), localStorage.setItem("lastPath", "ordering"))
      );
  }, [categoryId]);

  const getCategory = useCallback(async () => {
    await agent.Categories.getById(categoryId).then((res) => {
      setCategoryForm(res);
    });
  }, [categoryId]);

  useEffect(() => {
    getProducts();
    getCategory();
    window.scrollTo(0, 0);
  }, [getProducts, getCategory, pathname]);

  const productItems = information.displayProductsWithImage
    ? products(delivery.delivery).map((item) => (
        <ProductItemWithImage key={item.id} mainProduct={item} />
      ))
    : products(delivery.delivery).map((item) => (
        <ProductItemWithRedisCart key={item.id} mainProduct={item} />
      ));

  // ==========================tsx============================
  return (
    <>
      <Container>
        {categoryForm.description && (
          <Grid item xs={12}>
            <Typography className={classes.description}>
              {categoryForm.description}
            </Typography>
          </Grid>
        )}
      </Container>

      <Grid item xs={12} className={classes.foods}>
        {productItems}
      </Grid>
      <ScrollTop />
    </>
  );
};

export default ProductList2;
