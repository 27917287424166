const WavyFooter = ({
  firstWaveColor,
  secondWaveColor,
  footerHeight,
}: {
  firstWaveColor?: string;
  secondWaveColor?: string;
  footerHeight?: number;
}) => {
  return (
    <div
      style={{
        width: "100vw",
        overflow: "hidden",
        transform: "translate(0, 65px)",
        zIndex: 1,
      }}
    >
      <svg
        viewBox="0 -20 700 90"
        preserveAspectRatio="none"
        style={{
          width: "100%",
          height: 150,
        }}
      >
        <path
          transform="translate(0, -20)"
          d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700"
          fill={firstWaveColor + "d9"}
        />
        <path
          d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v50 h-700z"
          fill={secondWaveColor}
        />
      </svg>
    </div>
  );
};

export default WavyFooter;
