import React from "react";
import "./simpleArrow.scss";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
const SamplePrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div
      style={{
        border: "2px solid #fff",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 40,
        width: 40,
        height: 40,
      }}
      onClick={onClick}
      className="simpleArrow back"
    >
      <ArrowBackIosRoundedIcon />
    </div>
  );
};

export default SamplePrevArrow;
