import { MenuItem } from "@material-ui/core";
import { KeyboardArrowRightRounded } from "@material-ui/icons";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import {
  bindHover,
  bindMenu,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { StyledLink } from "../Design2/Navbar2/components/Menu/MenuStyles";
import { CHoverMenuStyles } from "./CHoverMenu.styles";

interface IProps {
  user: {
    firstName: string;
  };
  hoverMenuItems: {
    href?: string;
    text?: string;
    onClick?: () => void;
  }[];
}

const CHoverMenu = ({ user, hoverMenuItems }: IProps) => {
  const popupState = usePopupState({ variant: "popover", popupId: "userMenu" });
  const classes = CHoverMenuStyles();

  return (
    <div className={classes.hoverMenu}>
      <StyledLink {...bindHover(popupState)}>
        {user.firstName}
        <KeyboardArrowRightRounded fontSize="small" />
      </StyledLink>
      <HoverMenu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {hoverMenuItems.map((item, index) => (
          <StyledLink
            key={index}
            href={item.href}
            underline="none"
            className={classes.link}
            onClick={item.onClick}
          >
            <MenuItem onClick={popupState.close}>{item.text}</MenuItem>
          </StyledLink>
        ))}
      </HoverMenu>
    </div>
  );
};

export default CHoverMenu;
