import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const OffersStyles = makeStyles((theme: Theme) =>
  createStyles({
    offersRoot: {
      padding: "1rem",
    },
    paper: {
      padding: "1rem",

      "& h3, p": {
        textAlign: "center",
      },
      "& hr": {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
    offersList: {
      [theme.breakpoints.up("lg")]: {
        padding: "0 1rem",
      },
      "& ul": {
        paddingLeft: 20,
      },
      "& li": {
        marginBottom: 5,
        listStyle: "disc",
      },

      "& .price": {
        marginLeft: 5,
         
      },
    },
  })
);
