import { Grid, Paper, Typography } from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import AvTimerRoundedIcon from "@material-ui/icons/AvTimerRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import LocalMallRoundedIcon from "@material-ui/icons/LocalMallRounded";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { selectInformation } from "../../Stores/informationSlice";
import BackgroundImage from "../Common/BackgroundImage/BackgroundImage";
import { _404PageStyles } from "./_404PageStyles";

const _404Page = () => {
  const { t } = useTranslation();
  const classes = _404PageStyles();
  const information = useSelector(selectInformation);
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      {information.themeDisplay !== "1" && <BackgroundImage />}
      <Paper elevation={0} className={classes.paper}>
        <Typography variant="h4" align="center" color="secondary">
          {t("pageNotFind")}
        </Typography>

        <Typography variant="body2">
          We're sorry, but the page you're looking for cannot be found.
        </Typography>
        <Typography variant="body2">
          Please check the URL or try searching our website to find what you're
          looking for.
        </Typography>
        <div className={classes.links}>
          <NavLink onClick={() => history.goBack()} to="#">
            <ArrowBackRoundedIcon color="primary" />
            <Typography variant="button">{t("back")}</Typography>
          </NavLink>
          <NavLink to="/store/">
            <HomeRoundedIcon color="primary" />
            <Typography variant="button">{t("homePage")}</Typography>
          </NavLink>
          <NavLink to="/store/ordering/department">
            <LocalMallRoundedIcon color="primary" />
            <Typography variant="button">{t("orderNow")}</Typography>
          </NavLink>
          <NavLink to="/store/opening-time">
            <AvTimerRoundedIcon color="primary" />
            <Typography variant="button">{t("openingTimes")}</Typography>
          </NavLink>
        </div>
      </Paper>
    </Grid>
  );
};

export default _404Page;
