import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const RestaurantLandingPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      position: "relative",
      "@media only screen and (max-width: 600px)": {
        alignItems: "space-between",
      },

      "@media only screen and (min-width: 600px)": {
        paddingTop: "5rem",
      },

      "@media only screen and (min-width: 960px)": {
        paddingTop: "7rem",
      },

      "@media only screen and (min-width: 1280px)": {
        paddingTop: 0,
        alignItems: "space-between",
      },

      "@media only screen and (min-width: 1920px)": {
        paddingTop: "4rem",
        alignItems: "space-between",
      },

      "& .info": {
        width: "100%",
        padding: "1rem 1.5rem",
        textAlign: "center",

        color: theme.palette.text.primary,
        [theme.breakpoints.down("md")]: {
          borderRadius: 0,
        },

        [theme.breakpoints.up("lg")]: {},
        "& h2": {
          textAlign: "center",
          margin: 0,
          fontWeight: 400,
          [theme.breakpoints.down("xs")]: {
            fontSize: "2.2rem",
          },
        },
        "& h1": {
          textAlign: "center",
          color: theme.palette.primary.main,
          lineHeight: 1.1,
          [theme.breakpoints.down("xs")]: {
            fontSize: "2.5rem",
          },
          // [theme.breakpoints.up("lg")]: {
          //   width: 500,
          // },
          // [theme.breakpoints.up("xl")]: {
          //   width: 700,
          // },
        },
        "& .services": {
          textAlign: "center",
           
          [theme.breakpoints.down("xs")]: {
            fontSize: "1.3rem",
          },
          [theme.breakpoints.up("lg")]: {
            width: 400,
          },
        },
      },
    },
    description: {
      textAlign: "center",
      paddingBottom: 5,
      [theme.breakpoints.up("lg")]: {
        width: 400,
      },
    },
    actionContainer: {
      textAlign: "center",
      padding: "1rem 1.5rem",
    },
    action: {
      textAlign: "center",
      paddingTop: "2rem",

      [theme.breakpoints.up("lg")]: {
        "& button": {
          margin: 5,
        },
      },
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      "& button": {
        alignItems: "center",
         
        [theme.breakpoints.down("md")]: {
          margin: 5,
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
          margin: 5,
        },
      },
      "& .menus": {
        alignItems: "center",
        padding: 5,
        marginTop: 10,
        marginBottom: 10,
      },
    },
    orderStatusContainer: {
      paddingTop: "1rem",
      paddingLeft: "8rem",
    },
    preOrder: {
       
      [theme.breakpoints.up("lg")]: {
        width: 400,
      },
    },
    imageList: {
      // flexWrap: "nowrap",
      // // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      // transform: "translateZ(0)",
    },
    imageRoot: {
      padding: "1rem",
      //display: "block",
      //flexWrap: "wrap",
      //justifyContent: "space-around",
      //overflow: "hidden",
      // backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
    // buttonClass: {
    //   textAlign: "center" ,
    // },
    gridxs: {
      height: 200,
    },
    gridsm: {
      height: 300,
    },
    gridmd: {
      height: 350,
    },
  })
);
