import { ThemeProvider } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { IInformation } from "../Models/information";
import agent from "../Services/agent";
import { setLocalStorage } from "../Services/common";
import { setAllowDelivery } from "../Stores/alowDelivery";
import { setDelivery } from "../Stores/deliverySlice";
import { setDiscounts } from "../Stores/discountsSlice";
import { selectInformation, setInformation } from "../Stores/informationSlice";
import { MainSkinTheme } from "../Themes/MainSkinTheme";
import Footer from "../components/Design2/Footer/Footer";
import Navbar from "../components/Design2/Navbar2/Navbar";
import Loading from "../components/Loading/Loading";
import i18n from "../i18n";
import { useRoutes } from "../routes/Routes";

const Main = () => {
  const history = useHistory();
  const theme = MainSkinTheme();
  const dispatch = useDispatch();
  const information = useSelector(selectInformation);
  const routes = useRoutes();
  const [loading, setLoading] = useState(true);

  const getInformation = useCallback(async () => {
    if (localStorage.getItem("storeGuid")) {
      await agent.Information.get()
        .then((res) => {
          dispatch(setInformation(res));
          handleDeliveryState(res);
          setLocalStorage("DCDiscount", {
            deliveryOverallDiscount: res.deliveryOverallDiscount,
            collectionOverallDiscount: res.collectionOverallDiscount,
          });
          i18n.changeLanguage(res.lang === "Persian" ? "fa" : "en");
          let title = window.location.hostname.split(".")[0];
          if (title === "") title = "Food Order";
          if (title === "www") title = window.location.hostname.split(".")[1];
          if (res.langDisplay === "fa-IR") {
            document.body.setAttribute("dir", "rtl");
          }
          window.document.title = title !== "" ? title : "Food Order";
        })
        .finally(() => {
          if (information.allowOffers) getDiscounts();
          else setLoading(false);
        });
    } else {
      history.push("/");
    }
  }, []);

  const handleDeliveryState = (res: IInformation) => {
    if (res.minimumDeliveryTime <= 0) {
      dispatch(setAllowDelivery(false));
      dispatch(
        setDelivery({
          delivery: false,
          postCode: "",
          deliveryCharge: 0,
          minimumOrder: 0,
        })
      );
    } else {
      setLoading(true);
      agent.DeliveryTimes.canDelivery()
        .then((res) => {
          if (res) {
            dispatch(setAllowDelivery(true));
          } else {
            dispatch(setAllowDelivery(false));
            dispatch(
              setDelivery({
                delivery: false,
                postCode: "",
                deliveryCharge: 0,
                minimumOrder: 0,
              })
            );
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const getDiscounts = useCallback(() => {
    agent.TierPrices.getClientTierPrices()
      .then((res) => {
        dispatch(setDiscounts(res));
      })
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getInformation();
  }, []);

  if (loading) return <Loading />;
  // ==========================tsx============================
  return (
    <ThemeProvider theme={theme}>
      <div className="fullHeight">
        <Navbar
          address={information.tell ?? information.detailTell}
          telephone={information.phoneNumber}
          openOrder={information.openOrder}
          temporaryShopClose={information.temporaryShopClose}
        />
        <main style={{ height: "100%" }}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                render={(props) =>
                  route.component && <route.component {...props} />
                }
              />
            ))}
            <Route path="*">
              <Redirect to="/404" />
            </Route>
          </Switch>
        </main>
        {information.storeType !== "Kiosk" && (
          <Footer
            hasOnlinePayment={
              information.payPal ||
              information.worldPay ||
              information.paymentSense
            }
            hasPayPal={information.payPal}
            facebook={information.facebook}
            instagram={information.instagram}
            twitter={information.twitter}
            whatsapp={information.whatsapp}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default Main;
