import { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { IImageWebSite } from "../../Models/ImageWebSite";
import agent from "../../Services/agent";
import Loading from "../Loading/Loading";

const Logo = () => {
  const [logo, setLogo] = useState<IImageWebSite>();
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);

  const getLogo = useCallback(async () => {
    setIsLogoLoaded(true);
    await agent.ImageWebSite.getLogo()
      .then((res) => {
        if (res) {
          setLogo(res);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLogoLoaded(false));
  }, []);

  useEffect(() => {
    getLogo();
  }, []);

  return (
    <NavLink to="/store">
      {isLogoLoaded && <Loading />}
      {logo && (
        <img
          src={`${process.env.REACT_APP_IMG_URL}${logo.picPath}`}
          alt={logo.title}
        />
      )}
    </NavLink>
  );
};

export default Logo;
