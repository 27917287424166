import { TextField, TextFieldProps, styled } from "@material-ui/core";
import React from "react";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFormLabel-root": {
    color: theme.palette.text.primary,
  },
  "& .MuiSelect-root": {
    borderRadius: 15,
  },
  "& fieldset": {
    borderRadius: 15,
  },
}));

type IProps = TextFieldProps & {};

const GenericTextField: React.FC<IProps> = ({ ...props }) => {
  return <CustomTextField {...props} />;
};

export default GenericTextField;
