import { Box, Drawer, IconButton, Link } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { CloseRounded } from "@material-ui/icons";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../Stores/userSlice";
import { DrawerMenuStyles } from "./DrawerMenuStyles";

interface IMenuLink {
  name: string;
  to: string;
}
interface IProps {
  menu: IMenuLink[];
  address: string;
  tel: string;
  open: boolean;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const DrawerMenu: React.FC<IProps> = ({ menu, toggleDrawer, open }) => {
  const { t } = useTranslation();
  const classes = DrawerMenuStyles();
  const user = useSelector(selectUser);
  const isAdmin = user && user.roles.indexOf("Administrator") !== -1;
  const isUser = user && user.token;
  const information = useSelector((state: any) => state.information);

  return (
    <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
      <Box
        style={{
          height: "100%",
          minWidth: "100vw",
          backgroundColor: information.darkTheme ? "#424242" : "#fff",
        }}
      >
        <IconButton
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 3,
          }}
          onClick={toggleDrawer(false)}
        >
          <CloseRounded fontSize="large" />
        </IconButton>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <div className={classes.links}>
            {window.location.pathname === "/store" ? (
              <Link href="/store/ordering/departments" underline="none">
                <ListItem>
                  <ListItemText primary={t("orderNow")} />
                </ListItem>
              </Link>
            ) : (
              <Link href="/store" underline="none">
                <ListItem>
                  <ListItemText primary={t("home")} />
                </ListItem>
              </Link>
            )}
            {menu.map((item) => {
              return (
                <Link key={item.name} href={item.to} underline="none">
                  <ListItem button>
                    <ListItemText primary={t(item.name)} />
                  </ListItem>
                </Link>
              );
            })}
            {isUser && user.roles.indexOf("Guest") === -1 ? (
              <Fragment>
                {isAdmin ? (
                  <Link href="/panel" underline="none">
                    <ListItem>
                      <ListItemText primary={t("admin")} />
                    </ListItem>
                  </Link>
                ) : null}
                <Link href="/store/profile" underline="none">
                  <ListItem>
                    <ListItemText primary={t("profile")} />
                  </ListItem>
                </Link>
                <Link
                  href="/store/"
                  onClick={() => {
                    localStorage.removeItem("account");
                    window.location.replace("/");
                  }}
                  underline="none"
                >
                  <ListItem>
                    <ListItemText primary={t("signOut")} />
                  </ListItem>
                </Link>
              </Fragment>
            ) : (
              <Link href="/store/login" underline="none">
                <ListItem>
                  <ListItemText primary={t("signIn")} />
                </ListItem>
              </Link>
            )}
          </div>
        </List>
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;
