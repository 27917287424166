import { Box, Grid, ThemeProvider, Typography } from "@material-ui/core";
import { Storefront } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { TSubscriber } from "../Models/subscriber";
import { setLocalStorage } from "../Services/common";
import { SetStoreId } from "../Services/storeSession";
import { clearDiscounts } from "../Stores/discountsSlice";
import { clearInformation } from "../Stores/informationSlice";
import { emptyCart as orderEntryCartSlice } from "../Stores/orderEntryCartSlice";
import { emptyCart as redisCartSlice } from "../Stores/redisCartSlice";
import { emptyCart as redisOrderEntryCartSlice } from "../Stores/redisOrderEntryCartSlice";
import { MainSkinTheme } from "../Themes/MainSkinTheme";
import Footer from "../components/Design2/Footer/Footer";
import Loading from "../components/Loading/Loading";
import { SubscriberStyles } from "./Styles/Subscriber.styles";

const Subscriber = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = MainSkinTheme();
  const classes = SubscriberStyles();
  const [loading, setLoading] = useState(true);
  const [subscriber, setSubscriber] = useState<TSubscriber>();
  // =========================methods=========================

  const handleSelectStore = (storeId: string) => {
    SetStoreId(storeId);
    history.push("/store");
  };

  const handleDevModeStore = () => {
    if (process.env.NODE_ENV === "development") {
      setLocalStorage("singleStore", "true");
      handleSelectStore("c51911c1-4e52-4e92-859c-17474170566d");
    }
  };

  const getSubscribers = () => {
    fetch(
      // for API test replace "casaburritos.co.uk" with hostname`
      `${
        process.env.REACT_APP_API_URL
      }/api/settings/${window.location.hostname.replace(/^www\./, "")}`
    )
      .then((res) => res.json())
      .then((res: TSubscriber) => {
        if (res.stores.length === 1) {
          setLocalStorage("singleStore", "true");
          handleSelectStore(res.stores[0].id);
        } else setSubscriber(res);
      })
      .catch(() => handleDevModeStore())
      .finally(() => setLoading(false));
  };

  const handelCleanStore = () => {
    if (localStorage.getItem("storeGuid")) {
      localStorage.removeItem("storeGuid");
      dispatch(clearInformation());
      dispatch(redisCartSlice());
      dispatch(orderEntryCartSlice());
      dispatch(redisOrderEntryCartSlice());
      dispatch(clearDiscounts());
    }
  };

  useEffect(() => {
    handelCleanStore();
    getSubscribers();
  }, []);

  if (loading) return <Loading />;

  return (
    <ThemeProvider theme={theme}>
      <div className="fullHeight">
        <main className={classes.main}>
          <Grid container justifyContent="center" style={{ margin: "10px 0" }}>
            <Typography variant="h5">
              Select a store to start your order
            </Typography>
          </Grid>
          {subscriber?.stores?.map?.((store) => (
            <Box
              key={store.id}
              className={classes.store}
              onClick={() => handleSelectStore(store.id)}
            >
              {store.logoPath ? (
                <img
                  src={process.env.REACT_APP_IMG_URL + store.logoPath}
                  alt={store.logoName}
                />
              ) : (
                <Box className={classes.storeDefaultImg}>
                  <Storefront fontSize="large" />
                </Box>
              )}
              <Typography variant="body1" align="center">
                {store.name}
              </Typography>
            </Box>
          ))}
        </main>
        <Footer
          hasOnlinePayment={false}
          hasPayPal={false}
          facebook={""}
          instagram={""}
          twitter={""}
          whatsapp={""}
          isSubscriber
        />
      </div>
    </ThemeProvider>
  );
};

export default Subscriber;
