import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const SubscriberStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      height: "100%",
      flexWrap: "wrap",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      padding: "0 20px",
    },
    store: {
      display: "flex",
      flexDirection: "column",
      margin: "10px 15px",
      borderRadius: 20,
      border: `1px solid ${theme.palette.primary.light}30`,
      boxShadow: `0px 0px 15px 0px ${theme.palette.primary.light}20`,
      cursor: "pointer",
      padding: 10,
      "&:hover": {
        boxShadow: `0px 0px 15px 0px ${theme.palette.primary.light}50`,
      },

      "& img": {
        width: 100,
        height: 100,
        objectFit: "contain",
      },
    },
    storeDefaultImg: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 100,
      height: 100,

      "& svg": {
        padding: 10,
        width: 50,
        height: 50,
        color: theme.palette.primary.light + "70",
        borderRadius: "100%",
        border: `4px solid ${theme.palette.primary.light}40`,
      },
    },
  })
);
