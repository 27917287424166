import { Theme, createStyles, makeStyles, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import MenuItem from "@material-ui/core/MenuItem";

export const MenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu2: {
      display: "flex",
      alignItems: "center",
      padding: 5,

      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    link: {
      display: "block",
      color: theme.customPalette.menuText,

      "& li": {
        filter: "brightness(0.5)",
      },
      "&:hover": {
        color: "red",
      },
      "&:first-child": {
        paddingTop: 5,
        borderRadius: "5px 5px 0 0",
      },
      "&:last-child": {
        paddingBottom: 5,
        borderRadius: "0 0 5px 5px",
      },
    },
    hoverMenu: {
      padding: "0 5px",
      "&:last-child": {
        borderRight: "none",
      },
      "& a": {
        display: "flex",
        alignItems: "center",
        padding: "0 10px",

        color: `${theme.customPalette.menuText} !important`,
        cursor: "pointer",

        "&:hover": {
          color: theme.customPalette.menuText,
          filter: "brightness(0.5)",
          textDecoration: "none",
          opacity: 0.9,
        },
      },
    },
  })
);

export const StyledLink = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: "0 10px",
    color: `${theme.customPalette.menuText} !important`,
    cursor: "pointer",
    textDecoration: "none",
    fontWeight: 500,

    "&:last-child": {
      borderRight: "none",
    },

    "&::after": {
      content: '""',
      position: "absolute",
      bottom: -5,
      left: "15%",
      width: 0,
      height: "2px",
      backgroundColor: theme.palette.primary.main,
      transition: "width 0.3s ease-out",
    },

    "&:hover": {
      color: theme.palette.primary.main + " !important",
      textDecoration: "none",

      "&::after": {
        width: "70%",
      },
    },
  },
}))(Link);

export const StyledMenuItem = withStyles(() => ({
  root: {
    fontWeight: "bold",
  },
}))(MenuItem);
