import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const ProfileInvoiceStyles = makeStyles((theme: Theme) =>
  createStyles({
    profileInvoice: {
      padding: "0 1rem",

      "& .back": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1rem",
        "& svg": {
          fill: "#51861e",
        },
      },
      "& h5": {
        fontSize: "1rem",
         
        marginTop: "0.5rem",

        "& span": {
          fontSize: "1rem",
          fontWeight: "normal",
        },
      },

      "& .productTable": {
        "& th": {
          color: "#fff",
          backgroundColor: "#555",
          fontSize: "1rem",
           
        },

        "& tr": {
          "& &:first-child": {
             
          },
        },

        "& ul": {
          margin: 0,
          padding: 0,
        },

        "& img": {
          width: 70,
          objectFit: "cover",
          margin: 5,
        },

        "& .extra": {
          paddingLeft: "2rem",
        },

        "& .extraPrice": {
           
        },

        "& .shippingTable": {
          "& td:last-child": {
            textAlign: "right",
          },
        },
        "& .bold": {
          fontWeight: "600 !important",
          "& *": {
            fontWeight: "600 !important",
          },
        },
        "& .fs1rem": {
          "& *": {
            fontSize: "1rem !important",
          },
        },
        "& .discount": {
          "& *": {
            color: "#c00a28",
          },
        },
      },

      "& .currency": {
        "& &::before": {
           
        },
      },
    },
    price: {
       
      "& span": {
        display: "block",
        textAlign: "inherit",
        width: "auto",
      },
      "& .discount": {
        color: theme.palette.text.primary,
        textDecoration: "line-through",
        textDecorationThickness: "2px",
        textDecorationColor: "#CC0000",
      },
    },
    totals: {
      [theme.breakpoints.up("md")]: {
        width: "25%",
      },
      [theme.breakpoints.up("xl")]: {
        width: "30%",
      },
      margin: "0 0 0 auto",
    },
  })
);
